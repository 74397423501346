import { AbilityLib, AbilityType } from "../../../interfaces/Ability.interface";
import { butterBlast, cosmicLaser, crumbleCure, electronaut, muffinMelee } from "./fx";
import { ReactComponent as MuffinMelee } from '../../../collections/cosmicmuffins/abilities/cosmicmuffins_muffin-melee.svg';
import { ReactComponent as ButterBlast } from '../../../collections/cosmicmuffins/abilities/cosmicmuffins_butter-blast.svg';
import { ReactComponent as CosmicLaser } from '../../../collections/cosmicmuffins/abilities/cosmicmuffins_cosmic-laser.svg';
import { ReactComponent as CrumbleCure } from '../../../collections/cosmicmuffins/abilities/cosmicmuffins_crumble-cure.svg';
import { ReactComponent as Electronaut } from '../../../collections/cosmicmuffins/abilities/cosmicmuffins_electronaut.svg';

const abilities: AbilityLib[] = [
    {
        "name": "Butter Blast",
        "power": 50,
        "energy": 4,
        "group": true,
        "type": AbilityType.Attack,
        "self": false,
        "description": "Unleash a wave of buttery chaos, splattering across all enemies with significant force.",
        "cssClass": "butter-blast",
        "icon": <ButterBlast />,
        "method": butterBlast,
        "sound": {
            "title": "Pool Indoor Splash 2",
            "url": "https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/sfx/ES_Pool%20Indoor%20Splash%202%20-%20SFX%20Producer.mp3",
            "soundsLike": "pool indoor splash"
        }
    },
    {
        "name": "Cosmic Laser",
        "power": 25,
        "energy": 2,
        "group": false,
        "type": AbilityType.Attack,
        "self": false,
        "description": "Fire a concentrated beam of cosmic energy, burning through a single foe with precision.",
        "cssClass": "cosmic-laser",
        "icon": <CosmicLaser />,
        "method": cosmicLaser,
        "sound": {
            "title": "Laser Burst Descend 1",
            "url": "https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/sfx/ES_Laser%20Burst%20Descend%201%20-%20SFX%20Producer.mp3",
            "soundsLike": "laser shooting"
        }
    },
    {
        "name": "Muffin Melee",
        "power": 20,
        "energy": 2,
        "group": false,
        "type": AbilityType.Attack,
        "self": false,
        "description": "Engage in close combat, delivering a quick but powerful muffin smack to the enemy.",
        "cssClass": "muffin-melee",
        "icon": <MuffinMelee />,
        "method": muffinMelee,
        "sound": {
            "title": "Force Field Impact 1",
            "url": "https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/sfx/ES_Force%20Field%20Impact%201%20-%20SFX%20Producer.mp3",
            "soundsLike": "force field, impact or (punch)"
        }
    },
    {
        "name": "Crumble Cure",
        "power": 25,
        "energy": 2,
        "group": false,
        "self": true,
        "type": AbilityType.HealDefense,
        "description": "Restore lost crumbs and reinforce your structure, healing and defending yourself.",
        "cssClass": "crumble-cure",
        "icon": <CrumbleCure />,
        "method": crumbleCure,
        "sound": {
            "title": "Earthquake Explosion 6",
            "url": "https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/sfx/ES_Earthquake%20Explosion%206%20-%20SFX%20Producer.mp3",
            "soundsLike": "earthquake rocks"
        }
    },
    {
        "name": "Electronaut",
        "power": 75,
        "energy": 4,
        "group": true,
        "type": AbilityType.Attack,
        "self": false,
        "description": "Summon a surge of electrifying muffin energy, zapping all enemies with immense cosmic power.",
        "cssClass": "electronaut",
        "icon": <Electronaut />,
        "method": electronaut,
        "sound": {
            "title": "Electrical Shock 1",
            "url": "https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/sfx/ES_Electrical%20Shock%201%20-%20SFX%20Producer.mp3",
            "soundsLike": "electrical shock, loud"
        }
    }
];

export default abilities;