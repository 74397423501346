// Config
import { CollectionSlug } from '../config/collections';

// React
import { UserContext } from '../Context';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';

// Ionic
import { IonButton, IonContent, IonIcon, IonPage, useIonViewDidEnter } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';

// Interfaces
import { UserState } from '../interfaces/User.interface';

// Components
import PlayerCardDisplay from '../components/play/PlayerCardDisplay';

// Utils
import { tilt, titleCase } from '../Utils';

// Images
import { ReactComponent as WebsiteIcon} from './../images/icons/icon-website.svg';
import { ReactComponent as XLogo } from './../images/logos/logo-x.svg';
import { ReactComponent as OpenseaLogo } from './../images/logos/logo-opensea.svg';
import { ReactComponent as RaribleLogo } from './../images/logos/logo-rarible.svg';

// Interfaces
import { Stats, defaultStats } from '../interfaces/Token.interface';

// Services
import { getTokenStats } from '../supabase/api';

// Schema
import { TokenMetadata } from '../generated/TokenMetadata';

// Styles
import './Collectible.scss';
import CollectionDetailsAndLinks from '../components/ui/CollectionDetailsAndLinks';


const Collectible: React.FC = () => {

    const { router, collections, user } = useContext<UserState>(UserContext);
    const { collection, token } = useParams<{ collection: CollectionSlug, token: string }>();
    const [ collectionDetails ] = collections.filter((c) => c.slug === collection);
    const [ collectible, setCollectible ] = useState<TokenMetadata>();
    const [stats, setStats] = useState<Stats>(defaultStats);

    useIonViewDidEnter(() => {
        tilt('tilt-js-collection');
    });

    useEffect(() => {
        if (router?.routeInfo.pathname.startsWith('/collection/')) {

            const scrollContent = document.querySelector('.scrollable-page-content');
            scrollContent?.scrollTo({ top: 0, behavior: 'smooth' });

            setTimeout(() => {
                tilt('tilt-js-collectible');
            }, 250);
            // console.log('collection', collection)
            // console.log('token', token)

            user.collectibles.forEach((collectible) => {
                if (Number(collectible.token_id) === Number(token) && collectible.collection === collection) {
                    setCollectible(collectible);
                    getTokenStats(Number(token), collection).then((res) => {
                        setStats(res);
                    }).catch((err) => {
                        console.error(err);
                    })
                }
            });

        }
    }, [router?.routeInfo.pathname, collection, token, user.collectibles])

    return (
        <IonPage className="gladiator-page collectible">
            <IonContent fullscreen className='transparent'>
                <div className="w-100 h-100 relative z-4">
                    <div className="page-content">
                        <div className="relative row full-width">
                            <div className="column small-12 large-3 flex justify-center justify-start-l animate__animated animate__fadeIn">
                                <IonButton className="back-button" onClick={() => router?.goBack()} color="primary" fill="clear">
                                    <IonIcon slot="icon-only" icon={arrowBack} color='dark' className='text-drop-shadow' />
                                    <span className="button-text f5 ml2 text-drop-shadow">{collectionDetails?.name}
                                    </span>
                                </IonButton>
                            </div>
                            <div className="column small-12 large-6 flex justify-center tc animate__animated animate__fadeIn">
                                <h2 className='gladiator-arena text-drop-shadow'>{collectible?.name}</h2>
                            </div>
                            <div className="column small-12 large-3 flex justify-center justify-end-l items-center">

                            </div>
                        </div>
                        <div className="relative row scrollable-page-content">
                            <div className="gladiator-top-border" />
                            {/* <div className="gladiator-bottom-border" /> */}
                            <div className="column small-12 relative z-4 flex flex-wrap justify-center pb6">
                                <div className="row collectible-row">
                                    <div className="column small-12 large-5">
                                        <div className="relative sticky">
                                            <div className="player-card-container">
                                                {collectible ? (
                                                    <PlayerCardDisplay card={collectible} clickFlip />
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="column small-12 large-7 animate__animated animate__fadeIn flex flex-wrap items-center">
                                        <div className="collectible-info w-100 mb5 ph4">
                                            <p className="f6 pt-serif b mb0 ttu">Collection</p>
                                            <h3 className='gladiator-arena bb b--white-30'>{collectionDetails?.name}</h3>
                                            <h4 className='pt-serif fw6 f5 mt0 text-drop-shadow ttu'>Creator: <span className="ml2">{collectionDetails?.creator}</span></h4>
                                            <p className="collectible-number pt-serif mt0 mb0 b ttu">
                                                <span className="fw6">Token:</span> #{collectible?.token_id}
                                            </p>
                                            <div className="row">
                                                <div className="column small-12 ma0 pa0">
                                                    <div className="mv3">
                                                    <CollectionDetailsAndLinks collection={collection} collectionDetails={collectionDetails} />
                                                    </div>
                                                </div>
                                                <div className="column small-12 pr4-l">
                                                    <h4 className="gladiator-arena bb b--white-30 pb2 mb3">Stats</h4>
                                                    <div className="relative">
                                                        <ul className="stats flex flex-column justify-center list ma0 pa0 primary-font">
                                                            {Object.entries(stats).map(([key, value], i) => (key === 'collection' || key === 'token_id' ? null : (
                                                                <li key={i} className="w-100 flex justify-between mv1 fw6 lh-copy pt-serif bb b--white-10 pb2 w-50">
                                                                    <span className="stat-name mr3 fw6 white">{titleCase(key.replaceAll('_', ' '))}</span>
                                                                    <span className="stat-value ttu tr w-50">{value ? typeof value === 'number' ? value.toLocaleString() : value : 0}</span>
                                                                </li>
                                                            )))}
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="column small-12 pr4-l pt3">
                                                    <h4 className="gladiator-arena bb b--white-30 pb2 mb3">Metadata</h4>
                                                    <ul className='features traits flex flex-column justify-center list ma0 pa0 primary-font'>
                                                        {
                                                            collectible?.attributes?.map(
                                                                (trait, i) => trait.value && (
                                                                    <li key={i} className="w-100 flex justify-between mv1 fw6 lh-copy pt-serif bb b--white-10 pb2">
                                                                        <span className="trait-name mr3 fw6 white ttc w-50">{trait.trait_type}</span>
                                                                        <span className="trait-value ttc tr w-50">{trait.value}</span>
                                                                    </li>
                                                                )
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div id="tilt-js-collectible" />
            </IonContent>
        </IonPage>
    );
};

export default Collectible;
