// Config
import { GladiatorReceiverMainnet, GladiatorReceiverSepolia, TestTokenObject } from '../config/shop';

// React
import { UserContext } from '../Context';
import { useCallback, useContext, useEffect, useState } from 'react';

// Styles
import './Shop.scss';

// Ionic
import { IonBadge, IonButton, IonContent, IonFooter, IonHeader, IonIcon, IonModal, IonPage, IonTitle, IonToolbar, useIonAlert } from '@ionic/react';

// Interfaces
import { UserState } from '../interfaces/User.interface';
import { ShopItemCurrencies, ShopItemPayload } from '../interfaces/ShopItem.interface';
import { ERC20tokenABI } from '../interfaces/Uniswap.Interface';
// import { EquipmentType } from '../interfaces/Equipment.interface';

// Components
import ZeroxModal from '../components/0x/0xModal';
import ShopItemCard from '../components/shop/CardEquipment';

// Services
import { purchaseShopItem } from '../services/GameServer';
import { getItemsForSale } from '../supabase/api';

// Utils
import { tilt, titleCaseWords } from '../Utils';

// Images
import ethIcon from '../images/0x/eth.png';
import link from '../images/0x/link.png';
import usdc from '../images/0x/usdc.png';
import swapIcon from '../images/0x/swap.svg'
import frameIcon from '../images/icons/frames.svg';
import wearablesIcon from '../images/icons/wearables.svg';
import gladiiCoin from '../images/gladiator/gladiator-coin.png';

// Purchase Support
import mentalHealthIcon from '../images/icons/mental-health.svg';
import animalSanctuaryIcon from '../images/icons/animal-sanctuary.svg';
import cancerSupportIcon from '../images/icons/cancer-support.svg';
import gamePlayIcon from '../images/icons/game.svg';
import collectionIcon from '../images/icons/cards.svg';

// API
import { ShopItemType, ShopItemRow } from '../supabase/types';

// Contracts
import GladiatorReceiver from '../web3/contracts/gladiator-receiver.json';
import { document, eye, information, refresh, swapVerticalOutline } from 'ionicons/icons';

const Shop: React.FC = () => {

    const { collections, user, setZeroXModal, balanceCheck, shopItems, getShopItems, testnet, setWaitModal } = useContext<UserState>(UserContext);

    const [presentAlert] = useIonAlert();
    const [buyItemModal, setBuyItemModal] = useState<boolean>(false);
    const [allShopitems, setAllShopitems] = useState<ShopItemRow[]>([]);
    const [shopItem, setShopItem] = useState<ShopItemRow | null>(null);
    // const [loading, setLoading] = useState<boolean>(false);
    const [shopItemFilter, setShopItemFilter] = useState<ShopItemType>("frames");

    const tiltFX = () => {
        setTimeout(() => {
            // setLoading(false);
            tilt('tilt-js-shop');
        }, 250)
    }

    const getAllShopItems = useCallback(() => {
        getItemsForSale().then((res) => {
            setAllShopitems(res)
            tiltFX();
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    useEffect(() => {
        getAllShopItems();
        tiltFX();
    }, [shopItemFilter, getAllShopItems])
    
    /**
     * Handle Swap Error
     * @param error Error message
     */
    const handlePurchaseError = (error: any) => {

        let errorMessage = error.message;
        if (error.message.includes('insufficient funds')) {
            errorMessage = 'Insufficient funds to complete the transaction.';
        }

        setWaitModal(false);
        presentAlert({
            mode: 'ios',
            header: 'Transaction Error',
            message: `${errorMessage}`,
            buttons: ['OK'],
        })
    }

    /**
     * Handle Purchase Success
     */
    const handlePurchaseSuccess = () => {
        setWaitModal(false);
        presentAlert({
            mode: 'ios',
            header: 'Congratulations!!!',
            message: `Your upgrade is now available for gameplay!`,
            buttons: ['OK'],
            onDidDismiss: () => {
                setBuyItemModal(false);
                balanceCheck(testnet);
            }
        })
    }

    /**
     * Preview Buy Shop Item
     * @param item Shop Item
     */
    const previewBuyShopItem = (item: ShopItemRow) => {
        setShopItem(item);
        setBuyItemModal(true);
    }

    /**
     * Wait for Transaction Receipt
     * @param transactionHash Transaction Hash
     */
    async function waitForTransactionReceipt(transactionHash: string): Promise<void> {
        console.log(`Waiting for transaction to be mined...`);

        const interval = 3000; // Polling interval in milliseconds
        const timeout = 60000; // Timeout in milliseconds, e.g., 60 seconds

        const receipt = await new Promise((resolve, reject) => {
            const startTime = Date.now(); // Capture start time for timeout
            const intervalId = setInterval(async () => {
                try {
                    const receipt = await user.web3.eth.getTransactionReceipt(transactionHash);
                    if (receipt) {
                        clearInterval(intervalId);
                        resolve(receipt);
                        return;
                    } else {
                        console.log(`Transaction receipt not found yet, continuing to wait...`);
                    }
                } catch (error) {
                    console.log('Transaction receipt not found yet, continuing to wait...');
                }

                // Check for timeout
                if (Date.now() - startTime > timeout) {
                    clearInterval(intervalId);
                    handlePurchaseError('Timeout waiting for transaction receipt');
                }
            }, interval);
        });

        console.log('Transaction confirmed with receipt:', receipt);
    }

    /**
     * Buy Shop Item
     * @param item 
     * @param token 
     */
    const buyShopItem = async (item: ShopItemRow, token: 'ETH' | 'LINK' | 'USDC' | 'GLADII') => {

        if (!user.wallet || !shopItem) {
            return;
        }

        // Prepare contract
        const contract = new user.web3.eth.Contract(GladiatorReceiver, testnet ? GladiatorReceiverSepolia : GladiatorReceiverMainnet);

        // Switch Cost based on what is defined in the database
        let cost: string;
        switch (token) {
            case 'ETH':
                cost = `${item.cost_eth}`
                break;
            case 'LINK':
                cost = `${item.cost_usdc}`
                break;
            case 'USDC':
                cost = `${item.cost_usdc}`
                break;
            case 'GLADII':
                cost = `${item.cost_gladii}`
                break;

        }

        // Amount in Wei
        const amountInWei = user.web3.utils.toWei(`${cost}`, 'ether');

        /**
         * Purchase with ETH
         */
        if (token === 'ETH') {

            try {

            // Construct the transaction object
            const transactionParameters = {
                from: user.wallet,
                to: testnet ? GladiatorReceiverSepolia : GladiatorReceiverMainnet,
                value: amountInWei,
                gas: ''
            };

            // Estimate gas for the transaction
            const estimatedGas = await user.web3.eth.estimateGas(transactionParameters);

            // Add a buffer to the estimated gas (optional, for example, 10% more)
            const gasWithBuffer = Math.floor(estimatedGas * 1.1);

            // Include the estimated gas with buffer in the transaction parameters
            transactionParameters.gas = gasWithBuffer.toString();


                setWaitModal(true)

                // Send the transaction
                const transactionHash: string = await new Promise((resolve, reject) => {
                    user.web3.eth.sendTransaction(transactionParameters)
                        .on('transactionHash', resolve)
                        .on('error', reject);
                });

                // Prepare payload for server
                const payload: ShopItemPayload = {
                    wallet: user.wallet!,
                    itemID: shopItem?.id,
                    transactionHash,
                    testnet,
                    currency: ShopItemCurrencies.Ethereum,
                    amount: parseFloat(cost)
                }

                // Wait for transaction receipt
                waitForTransactionReceipt(transactionHash).then(async (receipt) => {
                    handlePurchaseSuccess();
                    setTimeout(() => {
                        getShopItems();
                    }, 2000)
                }).catch((error) => {
                    handlePurchaseError(error);
                });

                // Sent the transaction payload to the server
                purchaseShopItem(payload).catch((error) => {
                    console.error(error);
                });

            } catch (error) {
                handlePurchaseError(error);
            }

            /**
             * Purchase with USDC / LINK
             */
        } 
        
        /**
         * Purchase with USDC / LINK
         */
        if (token === 'USDC' || token === 'LINK') {

            if (user.erc20Balance < parseFloat(cost)) {
                presentAlert({
                    mode: 'ios',
                    header: 'Insufficient Funds',
                    message: `You do not have enough ${testnet ? 'LINK' : 'USDC'} to complete this transaction.`,
                    buttons: ['OK'],
                })
                return;
            }

            setWaitModal(true)

            // Get the token contract
            const token = TestTokenObject.tokens.find(token => testnet ? token.symbol === 'LINK' : token.symbol === 'USDC')?.address;
            const tokenContract = new user.web3.eth.Contract(ERC20tokenABI, token);

            // Get the current allowance
            const currentAllowance = await tokenContract.methods.allowance(user.wallet, testnet ? GladiatorReceiverSepolia : GladiatorReceiverMainnet).call();

            // If the current allowance is less than the amount to send, then we need to approve the transaction
            if (currentAllowance < amountInWei) {
                try {
                    await tokenContract.methods.approve(testnet ? GladiatorReceiverSepolia : GladiatorReceiverMainnet, amountInWei).send({ from: user.wallet! });
                } catch (error) {
                    handlePurchaseError(error);
                    return;
                }
            }

            // Prepare the deposit transaction
            const depositTx = contract.methods.deposit(token, amountInWei);

            // MetaMask will prompt the user to approve the transaction
            await depositTx.send({ from: user.wallet })
                .on('transactionHash', (transactionHash: string) => {

                    const payload: ShopItemPayload = {
                        wallet: user.wallet!,
                        itemID: shopItem?.id,
                        transactionHash,
                        testnet,
                        currency: testnet ? ShopItemCurrencies.Chainlink : ShopItemCurrencies.USDC,
                        amount: parseFloat(cost)
                    }

                    waitForTransactionReceipt(transactionHash).then(async (receipt) => {
                        handlePurchaseSuccess();
                        setTimeout(() => {
                            getShopItems();
                        }, 2000)
                    }).catch((error) => {
                        handlePurchaseError(error);
                    });

                    purchaseShopItem(payload).catch((error) => {
                        console.error(error);
                    });

                })
                .on('error', (error: any) => {
                    handlePurchaseError(error);
                });

        }

        /**
         * Purchase with GLADII
         */
        if (token === 'GLADII') {
            
            console.log('GLADII Purchase', item, token, cost);

            if (user.gladiiBalance < parseFloat(cost)) {
                presentAlert({
                    mode: 'ios',
                    header: 'Insufficient Funds',
                    message: `You do not have enough GLADII to complete this transaction.`,
                    buttons: ['OK'],
                })
                return;
            }

            setWaitModal(true)

            const payload: ShopItemPayload = {
                wallet: user.wallet!,
                itemID: shopItem?.id,
                transactionHash: '',
                testnet,
                currency: ShopItemCurrencies.Gladiiator,
                amount: parseFloat(cost)
            }

            purchaseShopItem(payload).then(() => {
                handlePurchaseSuccess();
                setTimeout(() => {
                    getShopItems();
                }, 2000)
            }).catch((error) => {
                handlePurchaseError(error);
            });


        }

    }

    return (
        <IonPage className='gladiator-page shop'>
            <IonContent fullscreen className='transparent'>
                <div className="w-100 h-100 relative z-4">
                    <div className="page-content">
                        <div className="relative row title-and-tools text-drop-shadow">
                            <div className="column small-12 large-2 tc hide-for-large pt2">
                                <h2 className='gladiator-arena mb1'>Shop</h2>
                            </div>
                            <div className="column small-12 large-5 flex justify-center">
                                <>
                                <div className="gladii-balance flex items-center mr3">
                                    <img src={gladiiCoin} alt="" className="token-logo mr2" />
                                    <IonBadge>
                                    <span className="number f5 f4-l pt-serif fw7">{user.gladiiBalance.toLocaleString()}</span>
                                    </IonBadge>
                                </div>
                                {!user.emailUser ? (
                                <>
                                    {testnet ? (
                                        <div className="usdc-balance flex items-center">
                                            <img src={link} alt="" className="token-logo mr2" />
                                            <IonBadge>
                                                <span className="number f5 f4-l pt-serif fw7">{user.erc20Balance.toFixed(4)}</span>
                                            </IonBadge>
                                        </div>
                                    ) : (
                                        <div className="usdc-balance flex items-center">
                                            <img src={usdc} alt="" className="token-logo mr2" />
                                            <IonBadge>
                                                <span className="number f5 f4-l pt-serif fw7">{user.erc20Balance.toFixed(4)}</span>
                                            </IonBadge>
                                        </div>
                                    )}
                                    <div className="eth-balance flex items-center ml3">
                                        <img src={ethIcon} alt="" className="token-logo mr2" />
                                        <IonBadge>
                                            <span className="number f5 f4-l pt-serif fw7">{user.ethBalance.toFixed(4)}</span>
                                        </IonBadge>
                                    </div>
                                </>
                                ) : null}
                                </>
                            </div>
                            <div className="column small-12 large-2 tc show-for-large">
                                <h2 className='gladiator-arena ml3'>Shop</h2>
                            </div>
                            <div className="column small-12 large-5 flex items-center">
                                {!user.emailUser ? (
                                <div className='w-100 tc mv2 mv0-l'>
                                    <IonButton color={'light'} onClick={() => setZeroXModal(true)}>
                                        <IonIcon icon={swapIcon} className='mr2' />
                                        <span className='f5 pt-serif fw7'>Swap Tokens</span>
                                    </IonButton>
                                </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="relative row scrollable-page-content content-start pb5">
                            <div className="gladiator-top-border" />
                            {/* <div className="gladiator-bottom-border" /> */}
                            <div className="column small-12 relative z-4 flex flex-wrap justify-center items-center mb4 text-drop-shadow">
                                <IonButton 
                                    onClick={() => setShopItemFilter('frames')}
                                    color={shopItemFilter === 'frames' ? 'light' : 'transparent'}
                                >
                                    <IonIcon src={frameIcon} className='mr2' size='large' />
                                    <span className='f5 pt-serif fw7'>Frames</span>
                                </IonButton>
                                <IonButton 
                                    className='ml3'
                                    onClick={() => setShopItemFilter('wearables')}
                                    color={shopItemFilter === 'wearables' ? 'light' : 'transparent'}
                                >
                                    <IonIcon src={wearablesIcon} className='mr2' size='large' />
                                    <span className='f5 pt-serif fw7'>Wearables</span>
                                </IonButton>
                                {/* <IonButton 
                                    onClick={() => setShopItemFilter('collectibles')}
                                    color={shopItemFilter === 'collectibles' ? 'light' : 'transparent'}
                                >
                                    <IonIcon src={collectionIcon} className='mr2' size='large' />
                                    <span className='f5 pt-serif fw7'>Collectibles</span>
                                </IonButton> */}
                            </div>
                            <div className="column small-12 relative z-4 flex flex-wrap justify-center items-start shop-item-container text-drop-shadow">
                                {shopItemFilter === 'frames' ? (
                                    <>
                                    <div className="db w-100 tc animate__animated animate__zoomIn">
                                        <p className='pt-serif f4 mv0 b'>Frames boost defense and health stats for battle.</p>
                                    </div>
                                    {allShopitems.filter(item => item.type === 'frames').map((item) => {
                                        return (
                                            <div key={`shop_item_${item.id}`}>
                                                <div className="flex flex-column justify-center items-center slide-content">
                                                    <ShopItemCard 
                                                        shopItem={item} 
                                                        cardFlip={false}
                                                        onClick={() => {
                                                            previewBuyShopItem(item);
                                                        }}
                                                    />
                                                    <div className="actions tc">
                                                        {/* <IonButton
                                                            color={'light'}
                                                            className="gladiator-arena gladiator-arena-border"
                                                            onClick={() => {
                                                                previewBuyShopItem(item);
                                                            }}
                                                        >
                                                            <span className='f5 pt-serif fw7'>Flip</span>
                                                            <IonIcon icon={refresh} className='ml2' />
                                                        </IonButton> */}
                                                        <IonButton
                                                            color={'light'}
                                                            className="gladiator-arena gladiator-arena-border"
                                                            onClick={() => {
                                                                previewBuyShopItem(item);
                                                            }}
                                                        >
                                                            <span className='f5 pt-serif fw7'>View</span>
                                                            {/* <IonIcon icon={document} className='ml2' /> */}
                                                        </IonButton>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    </>                                
                                ) : null}
                                {shopItemFilter === 'wearables' ? (
                                    <>
                                    <div className="db w-100 tc animate__animated animate__zoomIn">
                                        <p className='pt-serif f4 mv0 b'>Wearables boost defense, health, power, and enable abilities.</p>
                                    </div>
                                    {allShopitems.filter(item => item.type === 'wearables').map((item) => {
                                        return (
                                            <div key={`shop_item_${item.id}`}>
                                                <div className="flex flex-column justify-center items-center slide-content">
                                                    <ShopItemCard 
                                                        shopItem={item} 
                                                        cardFlip={false}
                                                        onClick={() => {
                                                            previewBuyShopItem(item);
                                                        }}
                                                    />
                                                    <div className="actions tc">
                                                        <IonButton
                                                            color={'light'}
                                                            className="gladiator-arena gladiator-arena-border"
                                                            onClick={() => {
                                                                previewBuyShopItem(item);
                                                            }}
                                                        >
                                                            <span className='f5 pt-serif fw7'>View</span>
                                                        </IonButton>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    </>                                
                                ) : null}
                                {shopItemFilter === 'collectibles' ? (
                                    <>
                                    <h2 className='gladiator-arena mt5 pt5 f2 animate__animated animate__zoomIn'>Coming Soon!</h2>
                                    {/* {allShopitems.filter(item => item.type === ShopItemType.COLLECTIBLES).map((item) => {
                                        return (
                                            <div key={`shop_item_${item.id}`}>
                                                <div className="flex flex-column justify-center items-center slide-content">
                                                    {item.type === ShopItemType.COLLECTIBLES && (
                                                        <CardWearable shopItem={item} />
                                                    )}
                                                    <div className="actions tc">
                                                        <IonButton
                                                            className="gladiator-arena gladiator-arena-border"
                                                            onClick={() => {
                                                                previewBuyShopItem(item);
                                                            }}
                                                        >
                                                            <span className="pt2 gladiator-arena">
                                                                {shopItems?.find(ownedItem => ownedItem.id === item.id) ? 'Owned' : `Buy for ${testnet ? item.cost_usdc : item.cost_eth} ${testnet ? 'LINK' : 'ETH'} `}
                                                            </span>
                                                        </IonButton>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })} */}
                                    </>                                
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>

                <div id="tilt-js-shop" />

                <ZeroxModal />

                <IonModal
                    className="shop-item-modal"
                    isOpen={buyItemModal}
                    onDidDismiss={() => setBuyItemModal(false)}
                    showBackdrop={true}
                    onDidPresent={() => {
                        tilt('tilt-js-shop');
                    }}
                >
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle><span className="dib pt2 mt1 gladiator-arena">Purchase Accessory</span></IonTitle>
                            <IonButton color={'transparent'} className='mr2' slot='end' onClick={() => setBuyItemModal(false)}>
                                <span className="pt-serif white b">Close</span>
                            </IonButton>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                        <div className="modal-content tc ph3 ph5-l h-100 flex-l flex-wrap items-center">
                            <div className="row relative full-width w-100">
                                <div className="column small-12 medium-5">
                                    <div className="sticky-stop-item">
                                        {shopItem?.id ? (
                                            <ShopItemCard 
                                                shopItem={shopItem}
                                                cardFlip={true}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                                <div className="column small-12 medium-7 flex justify-center items-center">
                                    <div className="sticky-stop-item-">
                                        <div className="modal-header pt3">
                                            <p className="pt-serif b ttu">
                                                {collections.filter(c => c.uuid === shopItem?.collection)[0]?.name}
                                            </p>
                                            <h2 className='gladiator-arena mb0'>
                                                {shopItem?.name}
                                            </h2>
                                        </div>
                                        <div className="modal-body ph4-l row">
                                            <div className="column small-12 lh-copy animate__animated animate__fadeIn pt-serif">
                                                <p className="b ttu mb0">Description</p>
                                                <p className='mt1'>{shopItem?.description}</p>
                                                <p>
                                                    <span className="b ttu">Type: </span>
                                                    <span>{shopItem?.type}</span>
                                                </p>
                                                <p className='tc flex flex-wrap justify-center'>
                                                    <span className="b ttu w-100 mb2">Stats:</span>
                                                    {shopItem?.metadata ? Object.entries(shopItem.metadata).map(([key, value]) => {
                                                        return (
                                                            <span key={`shop_item_${shopItem.id}_${key}`} className="db ttc w-70 flex justify-between">
                                                                <span className="ttu b">{titleCaseWords(key)}:</span> <span className="f4 b">{value}</span>
                                                            </span>
                                                        )
                                                    }) : null}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="full-width w-100">
                                <div className="charity row mb4 pb4 pt-serif">
                                    <div className="column small-12">
                                        <p className='lh-copy pb4 mb4 bb bt pt4 b--white-20 animate__animated animate__fadeIn'>All Gladiator game purchases support public good. When you buy accessories to enhance gameplay, you are supporting the following causes all around the world:</p>
                                    </div>
                                    <div className="column small-12 large-6 animate__animated animate__zoomIn">
                                        <IonIcon src={mentalHealthIcon} />
                                        <p className='b ttu'>Children's Mental Health</p>
                                        <p>Your purchase brightens a child's world, fostering mental wellness and joy.</p>
                                    </div>
                                    <div className="column small-12 large-6 animate__animated animate__zoomIn">
                                        <IonIcon src={animalSanctuaryIcon} />
                                        <p className='b ttu'>Animal Sanctuaries</p>
                                        <p>Each accessory you buy offers refuge to animals, nurturing safe sanctuaries.</p>
                                    </div>
                                    <div className="column small-12 large-6 animate__animated animate__zoomIn">
                                        <IonIcon src={cancerSupportIcon} />
                                        <p className='b ttu'>Cancer Support</p>
                                        <p>Buying game add-ons aids cancer support, spreading hope and comfort.</p>
                                    </div>
                                    <div className="column small-12 large-6 animate__animated animate__zoomIn">
                                        <IonIcon src={gamePlayIcon} />
                                        <p className='b ttu'>Gladiator Development</p>
                                        <p>Your purchase propels Gladiator development, crafting new adventures.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </IonContent>
                    <IonFooter class="tc">
                        <IonToolbar class="tc">
                            <div className="row flex justify-around items-center">
                                <IonButton
                                    disabled={shopItems?.find(ownedItem => ownedItem.id === shopItem?.id) ? true : false}
                                    className='mh2 pt-serif b'
                                    slot='end'
                                    color={'primary'}
                                    onClick={() => {
                                        buyShopItem(shopItem!, 'GLADII')
                                    }}
                                >
                                    Buy for <img src={gladiiCoin} alt="" className="token-logo mh2" /> {shopItem?.cost_gladii?.toLocaleString()} GLADII
                                </IonButton>
                                <IonButton
                                    disabled={shopItems?.find(ownedItem => ownedItem.id === shopItem?.id) ? true : false}
                                    className='mh2 pt-serif b'
                                    slot='end'
                                    color={'primary'}
                                    onClick={() => {
                                        buyShopItem(shopItem!, 'ETH')
                                    }}
                                >
                                    Buy for <img src={ethIcon} alt="" className="token-logo mh2" /> {shopItem?.cost_eth} ETH
                                </IonButton>
                                <IonButton
                                    disabled={shopItems?.find(ownedItem => ownedItem.id === shopItem?.id) ? true : false}
                                    className='mh2 pt-serif b'
                                    slot='end'
                                    color={'primary'}
                                    onClick={() => {
                                        buyShopItem(shopItem!, 'USDC')
                                    }}
                                >
                                    Buy for <img src={testnet ? link : usdc} alt="" className="token-logo mh2" /> {shopItem?.cost_usdc} {testnet ? `LINK` : `USDC`}
                                </IonButton>

                                {/* <PayPalButtons
                                    createOrder={(data, actions) => {
                                        return actions.order.create({
                                            purchase_units: [{
                                                amount: {
                                                    currency_code: "USD",
                                                    value: `${shopItem?.cost_usdc}` || "0.00",
                                                }
                                            }],
                                            intent: 'CAPTURE'
                                        });
                                    }}
                                    onApprove={async (data, actions) => {
                                        if (!actions.order) {
                                            return Promise.reject(new Error("Order is undefined"));
                                        }
                                        try {
                                            const details = await actions.order.capture();
                                            console.log('Transaction completed by ', details);
                                            handlePurchaseSuccess(); // Handle success the same way as other methods
                                        } catch (err) {
                                            handlePurchaseError(err);
                                        }

                                    }}
                                    onError={(err) => {
                                        handlePurchaseError(err);
                                    }}
                                /> */}

                            </div>
                        </IonToolbar>
                    </IonFooter>
                </IonModal>

            </IonContent>
        </IonPage>
    );
};

export default Shop;
