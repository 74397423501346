// React
import { useContext, useEffect } from 'react';
import { UserContext } from '../Context';

// Ionic
import { IonButton, IonContent, IonHeader, IonModal, IonTitle, IonToolbar } from '@ionic/react';

// Styles
import './Home.scss';

// Welcome Images
import gladiatorCoin from '../images/gladiator/gladiator-coin.png';

// Components
import WelcomeBlock from '../components/ui/WelcomeBlock';
import CollectionButton from './CollectionButton';

// Utils
import { tilt } from '../Utils';

const Welcome: React.FC = () => {

  const { collections, chooseTheme, setChooseTheme, changeTheme } = useContext(UserContext);
  
  // Initialize tilt on mount
  useEffect(() => {
    initTilt()
  }, [])

  /**
   * Initialize vanilla tilt
   */
  const initTilt = (timeout: number = 100) => {
    setTimeout(() => {
      tilt('tilt-js-welcome');
    }, timeout)
  }

  return (
      <>
      <WelcomeBlock />
      <IonModal isOpen={chooseTheme} backdropDismiss={false} className='theme-modal'>
        <IonHeader>
          <IonToolbar>
            <div className="row ma0 pa0">
              {/* <div className="columns ma0 pa0 small-4 tl flex items-center">
                <img src={brLogo} width={28} height={28} alt="" className='ml3' />
              </div> */}
              <div className="columns ma0 pa0 small-6 tl flex items-center">
                <IonTitle class='mt2 ml3'><span className="gladiator-arena">Choose Theme</span></IonTitle>
              </div>
              <div className="columns ma0 pa0 small-6 tr flex justify-end items-center">
                <IonButton slot='end' color={'transparent'} onClick={() => setChooseTheme(false)} className='mr3'>Close</IonButton>
              </div>
            </div>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="row">
            <div className="collection-options small-12 w-100 flex flex-wrap justify-center">
              {collections.filter(c => c.partner).map((c, i) => {
                return (
                  <CollectionButton
                    key={`collection-button-${i}`}
                    collection={c}
                    selected={null}
                    themeButton={true}
                    setCollection={() => {
                      changeTheme(c.slugLocal)
                    }}
                  />
                )
              })}

                <div className={`flex w-100`}>
                  <IonButton
                      mode="md"
                      className={`collection-button theme-button animate__animated animate__zoomIn`}
                      color={'transparent'}
                      onClick={() => {
                        changeTheme(null)
                      }}            
                  >
                          <div className="column inner-block relative">
                              <div className="border-accent outer z-0" />
                              <img src={gladiatorCoin} alt="" className='logo' style={{
                                height: '220px',
                              }} />
                          </div>
                  </IonButton>
                </div>

            </div>
          </div>
        </IonContent>
      </IonModal>
    </>
  );
};

export default Welcome;
