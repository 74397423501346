// React
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

// Ionic
import { Storage } from '@ionic/storage';
import Settings from './components/ui/Settings';
import { UserState } from './interfaces/User.interface';
import { UserContext } from './Context';

// Prepare local storage
const storage = new Storage();
storage.create();

interface VolumeContextProps {
  volume_Music: number;
  setVolume_Music: React.Dispatch<React.SetStateAction<number>>;
  volume_SFX: number;
  setVolume_SFX: React.Dispatch<React.SetStateAction<number>>;
  audioPlaying: boolean;
  setAudioPlaying: React.Dispatch<React.SetStateAction<boolean>>;
}

const VolumeContext = createContext<VolumeContextProps | undefined>(undefined);

interface VolumeProviderProps {
  children: ReactNode;
}

export const VolumeProvider: React.FC<VolumeProviderProps> = ({ children }) => {

  const { settings, setSettings } = useContext<UserState>(UserContext);
  const [audioPlaying, setAudioPlaying] = useState<boolean>(false);

  // Volume Settings
  const [volume_SFX, setVolume_SFX] = useState(0.60);
  const [volume_Music, setVolume_Music] = useState(0.05);

  // Load volume settings from local storage
  useEffect(() => {
    storage.get('volume_Music').then((value) => {
      if (value) {
        setVolume_Music(value);
      }
    });
    storage.get('volume_SFX').then((value) => {
      if (value) {
        setVolume_SFX(value);
      }
    });
  }, []);

  useEffect(() => {
    // console.log('Volume Music: ', volume_Music);
    // storage.set('volume_Music', volume_Music);
    // console.log('Volume SFX: ', volume_SFX);
    // storage.set('volume_SFX', volume_SFX);
  }, [volume_Music, volume_SFX]);

  return (
    <VolumeContext.Provider value={{ 
      volume_Music, 
      setVolume_Music, 
      volume_SFX, 
      setVolume_SFX,
      audioPlaying,
      setAudioPlaying
    }}>
      {children}
      <Settings isOpen={settings} setIsOpen={isOpen => setSettings(isOpen)} />
    </VolumeContext.Provider>
  );
};

/**
 * Hook to use the Volume Context
 * @returns Volume Context
 */
export const useVolume = (): VolumeContextProps => {
  const context = useContext(VolumeContext);
  if (!context) {
    throw new Error('useVolume must be used within a VolumeProvider');
  }
  return context;
};
