export enum BackdropTypes {

    // Sacred Moths
    SacredMothTerrarium = 'sacred-moth-terrarium',
    SacredPalaceShip = 'sacred-palace-ship',
    MothMonument = 'moth-monument',
    SpaceColosseum = 'space-colosseum',

    // Comsmic Muffins
    PlanetMacaron = 'planet-macaron',
    PlanetMacaronSnow = 'planet-macaron-snow',
    MacaronAtmosphere = 'planet-macaron-atmosphere',
    MacaronArctic = 'planet-macaron-arctic',

    // The Buns
    BunTown = 'bun-town',
    BunHills = 'bun-hills',

    // Bedlams
    WastelandCity = 'wasteland-city',
    WastelandIndustrial = 'wasteland-industrial',

    // Bufficorns
    BufficornSkyline = 'bufficorn-skyline',
    BufficornMeadow = 'bufficorn-meadow',
    BufficornSkylineVapor = 'bufficorn-skyline-vapor',
    BufficornWinterSkies = 'bufficorn-winter-skies',
    BufficornSpring = 'bufficorn-spring',
    BufficornDenverDrive = 'bufficorn-denver-drive',
    
    // Philosophers
    HallsOfWisdom = 'halls-of-wisdom',

}

export const BackdropTypesArray = Object.values(BackdropTypes);