// React
import { useContext, useEffect, useState } from 'react';
import { PlayContext, PlayState } from '../../pages/PlayContext';
import { UserContext } from '../../Context';

// Interfaces
import { UserState } from '../../interfaces/User.interface';

// Ionic
import { IonButton, IonHeader, IonIcon, useIonAlert } from '@ionic/react';
import { close, logOut, settingsSharp, walletSharp } from 'ionicons/icons';

// Images
import logoCoin from './../../images/gladiator/gladiator-coin.png'

// Utils
import { shortenWalletAddress } from '../../Utils';
import { leave } from '../../services/GameServer';


const Header: React.FC = () => {
    
    // User Context
    const { 
        router, 
        user, 
        collections, 
        theme, 
        settings, 
        setSettings, 
        audioPlaying, 
        walletInfo, 
        setWalletInfo, 
        logout 
    } = useContext<UserState>(UserContext);

    // Play Context
    const {

        // State
        room,
        resetGame,
        gameStart,
        randomBackdrop,

    }: PlayState = useContext(PlayContext);


    const [mode, setMode] = useState<'home' | 'play'>('home');
    const [settingIcon, setSettingIcon] = useState<'settings' | 'speaker'>('speaker');
    const [logoSmall, setLogoSmall] = useState<string | null>(logoCoin);
    const { otherPlayerConnected }: PlayState = useContext(PlayContext);

    /**
     * Ion Alert
     */
    const [ presentAlert, dismissAlert ] = useIonAlert();

    useEffect(() => {
        if (theme) {
            collections.forEach(collection => {
                if (collection.slug === theme && collection.logoSmall) {
                    setLogoSmall(collection.logoSmall);
                }
            });
        } else {
            setLogoSmall(null);
        }
    }, [theme, collections])

    useEffect(() => {
        if (router?.routeInfo.pathname.startsWith('/play/')) {
            setMode('play');
        } else {
            setMode('home');
        }
    }, [router?.routeInfo.pathname]);

    useEffect(() => {
        if (audioPlaying) {
            setSettingIcon('speaker');
        } else {
            setSettingIcon('settings');
        }
    }, [audioPlaying]);

    useEffect(() => {
        // console.log('user', user);
    }, [user]);

    return (
        <>

        <IonHeader className={`${otherPlayerConnected ? `play` : ``} gladiator-header`}>
            <div className="w-100 flex justify-between items-center mv2">
                <div>
                    <IonButton 
                        id='settings-button'
                        color={`transparent`} 
                        className='ml3 relative z-2'
                        onClick={() => setSettings(!settings)}
                    >
                    <span className="show-for-large mr2"><span className="button-text f5">Settings</span></span>
                    {settingIcon === 'speaker' && (
                        <div className="sound-bars animate__animated animate__zoomIn">
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                        </div>
                    )}
                    {settingIcon === 'settings' && (
                        <IonIcon 
                            icon={settingsSharp}
                            color='dark'
                            className='animate__animated animate__zoomIn'
                            size='small'
                            style={{
                                marginInlineStart: 0,
                                marginInlineEnd: 0,
                            }}
                        />
                    )}
                    </IonButton>
                    {gameStart !== null && randomBackdrop && (
                        <IonButton 
                            id='leave-button'
                            color={`transparent`} 
                            className='ml3 relative z-2'
                            onClick={() => {
                                presentAlert({
                                    mode: 'ios',
                                    header: 'Confirm',
                                    message: 'Are you sure you want to leave?',
                                    buttons: [
                                        {
                                            text: 'Cancel',
                                            role: 'cancel',
                                            handler: () => {
                                                dismissAlert();
                                            }
                                        },
                                        {
                                            text: 'Leave',
                                            handler: () => {
                                                if (room) {
                                                    leave(user, room).then(() => {
                                                        resetGame(false);
                                                        router?.push('/');
                                                    });
                                                }
                                            }
                                        }
                                    ]
                                });
                            }}
                        >
                        <span className="mr2-l">
                            <IonIcon 
                                icon={logOut}
                                color='dark'
                                className='animate__animated animate__zoomIn rotate-180'
                                size='small'
                                style={{
                                    marginInlineStart: 0,
                                    marginInlineEnd: 0,
                                }}
                            />
                        </span>
                        <span className="show-for-large"><span className="button-text f5">Retreat</span></span>
                        </IonButton>
                        
                    )}
                </div>
                <div className="logo-container animate__animated animate__zoomIn relative">
                    <img src={logoSmall ? logoSmall : logoCoin} alt="" width={mode === 'home' ? 100 : 88} className='logo' />
                </div>
                <div>
                    {(user?.wallet) && (
                        <IonButton 
                            id='wallet-button'
                            color={`transparent`}
                            className='mr3 relative z-2'
                            onClick={() => setWalletInfo(!walletInfo)}
                        >
                            {user.emailUser ? (
                                <span className="button-text f5 mr2 show-for-large truncate w5"
                                    style={{
                                        maxWidth: '155px',
                                    }}
                                >{user.email}</span>
                            ) : (
                                <span className="button-text f5 mr2 show-for-large">{shortenWalletAddress(user.wallet)}</span>
                            )}
                            <IonIcon 
                                icon={walletSharp}
                                color='dark'
                                className='animate__animated animate__zoomIn'
                                size='small'
                                style={{
                                    marginInlineStart: 0,
                                    marginInlineEnd: 0,
                                }}
                            />
                        </IonButton>                        
                    )}
                    <IonButton color={`transparent`} className='mr3 relative z-2' onClick={() => logout() }>
                        <span className="button-text f5 mr2 show-for-large">Logout</span>
                        <IonIcon 
                            icon={close}
                            color='dark'
                            className='animate__animated animate__zoomIn'
                            size='small'
                            style={{
                                marginInlineStart: 0,
                                marginInlineEnd: 0,
                            }}
                        />
                    </IonButton>
                </div>
            </div>
        </IonHeader>
        </>
    );
};

export default Header;
