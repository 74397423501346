import { AbilityLib, AbilityType } from "../../../interfaces/Ability.interface";
import { ReactComponent as BuffBash } from '../../../collections/bufficorns/abilities/bufficorns_buffbash.svg';
import { ReactComponent as SporkLift } from '../../../collections/bufficorns/abilities/bufficorns_sporklift.svg';
import { ReactComponent as MergeConflict } from '../../../collections/bufficorns/abilities/bufficorns_mergeconflict.svg';
import { ReactComponent as SporkWhaleShield } from '../../../collections/bufficorns/abilities/bufficorns_sporkwhale.svg';
import { buffBash, mergeConflict, sporkSurge, sporkWhale } from "./fx";

const abilities: AbilityLib[] = [
    {
        "name": "Bufficorn Charge",
        "power": 60,
        "energy": 2,
        "group": false,
        "type": AbilityType.Attack,
        "self": false,
        "description": "An unstoppable force that breaks through walled gardens and gatekeepers.",
        "cssClass": "buff-bash",
        "method": buffBash,
        "icon": <BuffBash />,
        "sound": {
            "title": "Swoosh and impact",
            "url": "https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/sfx/Buff%20Bash_mixdown.mp3",
            "soundsLike": "Super hero flying swoosh and impact"
        }
    },
    {
        "name": "Spork Surge",
        "power": 50,
        "energy": 3,
        "group": true,
        "type": AbilityType.Attack,
        "self": false,
        "description": "Unleash a decentralized energy surge, chaining through all enemies.",
        "cssClass": "spork-surge",
        "method": sporkSurge,
        "icon": <SporkLift />,
        "sound": {
            "title": "Electrical Surge",
            "url": "https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/sfx/Spork%20Surge_mixdown.mp3",
            "soundsLike": "Super hero flying swoosh and impact"
        }
    },
    {
        "name": "Efficiency Pulse",
        "power": 45,
        "energy": 3,
        "group": true,
        "type": AbilityType.Attack,
        "self": false,
        "description": "Sends out a wave that optimizes smart contract execution and eliminates redundant processes.",
        "cssClass": "merge-conflict",
        "method": mergeConflict,
        "icon": <MergeConflict />,
        "sound": {
            "title": "Warp and shoot",
            "url": "https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/sfx/Merge%20Conflict_mixdown.mp3",
            "soundsLike": "Warped sound and shooting"
        }
    },
    {
        "name": "Adaptive Armor",
        "power": 30,
        "energy": 2,
        "group": false,
        "type": AbilityType.HealDefense,
        "self": true,
        "description": "Summon the spirit of the mighty SporkWhale using fluidic shield that absorbs volatility and counters attacks.",
        "cssClass": "sporkwhale-shield",
        "method": sporkWhale,
        "icon": <SporkWhaleShield />,
        "sound": {
            "title": "Charging sound",
            "url": "https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/sfx/SporkwhaleShield_mixdown.mp3",
            "soundsLike": "Charging sound"
        }
    }
];

export default abilities;