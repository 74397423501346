import React from 'react';
import { IonIcon } from '@ionic/react';
import { CollectionDetails, Network } from '../../config/collections.interface';
import { mainnet_network_to_readable } from '../../config/utils';
import ethereumLogo from './../../images/chain-logos/ethereum.svg';
import baseLogo from './../../images/chain-logos/base.svg';
import abstractLogo from './../../images/chain-logos/abstract.svg';

interface ChainLogoProps {
    collectionDetails: CollectionDetails;
}

const ChainLogo: React.FC<ChainLogoProps> = ({ collectionDetails }) => {

    const getChainLogo = (network: Network) => {
        switch (network) {
            case Network.Mainnet:
                return ethereumLogo;
            case Network.Base:
                return baseLogo;
            case Network.Abstract:
                return abstractLogo;
            default:
                return undefined;
        }
    };

    return (
        <span className='chain-logo flex items-center'>
            {collectionDetails?.mainnet_network ? (
                <>
                <span className="chain-icon">
                    <IonIcon src={getChainLogo(collectionDetails.mainnet_network as Network)} />
                </span>
                <span className="chain-name pt-serif ttu mb1 mr3">
                    {mainnet_network_to_readable(collectionDetails.mainnet_network as Network)}
                </span>
                </>
            ) : null}
        </span>
    );
};

export default ChainLogo;
