import { objectPath_buns } from "..";
import { applyEffects, calculateTranslation } from "../../../fx/fx-utils";
import { TokenMetadata } from "../../../generated/TokenMetadata";
import { AbilityMode } from "../../../types/Abilities.type";

/**
 * Bushido Bun: Card Moves Across Screen
 * @param c1 Source card
 * @param c2 Target card
 * @param mode Ability mode
 * @returns Promise<boolean>
 */
export function bushidoBun(c1: TokenMetadata, c2: TokenMetadata, mode: AbilityMode) {
    return new Promise<boolean>((resolve) => {

        const source = document.getElementById(`card_${c1.wallet}_${c1.collection}_${c1.token_id}`);
        const target = document.getElementById(`card_${c2.wallet}_${c2.collection}_${c2.token_id}`);
    
        if (source && target) {
            const {
                translateX,
                translateY
            } = calculateTranslation(source, target);
    
            source.style.transform = `translate(${translateX}px, ${translateY}px)`;
            applyEffects('bushido-bun', mode, target, source, 1000).then(() => {
                source.style.transform = '';
                resolve(true)
            })
    
        }

    })
}

/**
 * Carrot Guard: Card Moves Across Screen
 * @param c1 Source card
 * @param c2 Target card
 * @param mode Ability mode
 * @returns Promise<boolean>
 */
export function carrotGuard(c1: TokenMetadata, c2: TokenMetadata, mode: AbilityMode) {
    return new Promise<boolean>((resolve) => {

        const source = document.getElementById(`card_${c1.wallet}_${c1.collection}_${c1.token_id}`);
        const target = document.getElementById(`card_${c2.wallet}_${c2.collection}_${c2.token_id}`);
    
        if (source && target) {
            const {
                translateX,
                translateY
            } = calculateTranslation(source, target);
    
            source.style.transform = `translate(${translateX}px, ${translateY}px)`;
            applyEffects('carrot-guard', mode, target, source, 1250).then(() => {
                source.style.transform = '';
                resolve(true)
            })
    
        }

    })
}

/**
 * Hat Trick: Group Attack with Magic
 * @param c1 Source card
 * @param c2 Target cards
 * @param mode Ability mode
 * @returns Promise<boolean>
 */
export function hatTrick(c1: TokenMetadata, c2: TokenMetadata[], mode: AbilityMode) {
    return new Promise<boolean>((resolve) => {

        const source = document.getElementById(`card_${c1.wallet}_${c1.collection}_${c1.token_id}`);

        if (source && c2) {

            /**
             * Display battle damage effect to the target
             */
            const targets = c2
            .map(c => document.getElementById(`card_${c.wallet}_${c.collection}_${c.token_id}`)).filter(t => t !== null);  // Filter out null values
            applyEffects('hat-trick', mode, targets as HTMLElement[], undefined, 750, 750).then(() => {
                resolve(true)
            })



            /**
             * Insert hat trick animation DOM element
             */
            const fxDiv = document.createElement('div');
            fxDiv.className = `fx hat-trick center-stg ${mode === 'incoming' ? `incoming` : ``}`;

            // Append an image inside of the div
            const img = document.createElement('img');
            img.src = `${objectPath_buns}/fx/hat-trick/hat-trick-a.png`;
            img.alt = 'hat-trick';
            fxDiv.appendChild(img);

            // Append the bun after the hat trick
            const bun = document.createElement('img');
            bun.src = `${objectPath_buns}/fx/hat-trick/bun.gif`;
            bun.className = 'bun';
            fxDiv.appendChild(bun);

            // Append the div to the center stage
            document.querySelector('#center-stage')?.appendChild(fxDiv);

            /**
             * Cleanup the view
             */
            setTimeout(() => {
                document.querySelector('.fx.hat-trick.center-stg')?.remove();
                resolve(true);
            }, 1750);

        }

    })
}

/**
 * Thumper Bomb: Projectile
 * @param c1 Source card
 * @param c2 Target card
 * @param mode Ability mode
 * @returns Promise<boolean>
 */
export function thumperBomb(c1: TokenMetadata, c2: TokenMetadata, mode: AbilityMode) {
    return new Promise<boolean>((resolve) => {

        const source = document.getElementById(`card_${c1.wallet}_${c1.collection}_${c1.token_id}`);
        const target = document.getElementById(`card_${c2.wallet}_${c2.collection}_${c2.token_id}`);

        if (source && target) {

            /**
             * Prepare projectile DOM element for source
             */
            const projectile = document.createElement('div');
            projectile.id = `thumper-bomb-projectile_${c1.token_id}`
            projectile.className = `thumper-bomb-projectile animate__animated animate__bounceIn cardAbility ${mode === 'cardAbility' ? `attack` : `incoming`}`;

            // Append the projectile to the source
            source.appendChild(projectile);
            const projectileElement = document.getElementById(projectile.id);
            if (projectileElement)  {

                // Calculate the translation
                const {
                    translateX,
                    translateY
                } = calculateTranslation(source, target);
        
                // Wait a moment for the projectile to appear
                setTimeout(() => {

                    // Add the ignite class to the projectile
                    projectileElement.classList.add('ignite');

                    // Move the projectile to the target
                    setTimeout(() => {
                        Object.assign(projectileElement.style, {
                            transform: `translate(${translateX}px, ${translateY}px)`,
                        });

                        // Explode the projectile
                        setTimeout(() => {
                            projectileElement.classList.add('explode');

                            setTimeout(() => {
                                Object.assign(projectileElement.style, {
                                    transform: `translate(${translateX}px, ${translateY}px) scale(1.5)`,
                                    opacity: 0
                                });
                            }, 150)

                            applyEffects('thumper-bomb', mode, target, source, 650, 0).then(() => {
                                projectileElement?.remove();
                                resolve(true)
                            })

                        }, 250)
                    }, 500)

                }, 500)  

            }
    
        }

    })
}
