import React from 'react';

const About: React.FC = () => {
    return (
        <div className='row mb6'>
            <div className="column small-12 pt-serif">
                <div className="ph3 lh-copy">

                    <h2 className="gladiator-arena">Origin of the Name</h2>
                    <p>The name Gladiator is inspired by the legendary warriors of ancient Rome—fighters who battled for glory, freedom, and survival in the grand arenas. Just like those gladiators, players in Gladiator step into a digital colosseum where skill, strategy, and adaptability determine victory.</p>
                    <p className='mb4'>The concept of Gladiator is also deeply personal to its founder, Dan Hendrickson. Growing up in the '90s, Dan was immersed in the golden age of collectible card games and video games—experiences that shaped his vision for a game where players could truly own, trade, and play with their digital assets. With Web3 technology, that vision became possible. Much like the gladiators who fought to carve out their own destiny, Gladiator gives players real control over their in-game economy, turning every match into both a battle and an opportunity to build a lasting legacy.</p>

                    <h2 className="gladiator-arena">Mission</h2>
                    <p className='mt0 mb4'>Gladiator's mission is to bring real utility to digital collectibles (NFTs) by creating a seamless, visually stunning, and addictive collectible card game. We bridge the gap between gaming and Web3, making NFT gameplay fun and accessible for all.</p>

                    <h2 className='gladiator-arena'>Vision</h2>
                    <p className='mt0 mb4'>Founded by Dan Hendrickson, Gladiator is built on the belief that NFTs should be more than just collectibles—they should be playable, tradeable, and fully integrated into the gaming experience. Inspired by collectible comic cards, and video games from the 90s, Gladiator combines nostalgia with cutting-edge blockchain technology to create the future of digital collectible gaming.</p>

                    <h2 className='gladiator-arena'>Values</h2>
                    <p className='mt0 mb4'>Gladiator is committed to creating a safe, fun, and creative community. We believe in the power of blockchain technology to revolutionize the gaming industry, and we are dedicated to making that future accessible to everyone. We are transparent, community-driven, and passionate about creating a game that is as fun to play as it is to collect.</p>

                    <h2 className="gladiator-arena">Collaboration</h2>
                    <p className='mt0 mb4'>Integrating an NFT collection into Gladiator is a collaborative process between Dan's Brokenreality team and the creator of the NFT collection. This partnership brings each collection to life through:</p>
                    <ul className='mb4'>
                        <li>Custom Card Art – Designed to match the unique style of the NFT collection.</li>
                        <li>Backgrounds & Special Animations – Enhancing gameplay with immersive visuals.</li>
                        <li>Music & Sound Effects – Tailored to create a unique atmosphere for each collection.</li>
                    </ul>
                    <p className='mb4'><strong>Only good, earnest, and honest creators who nurture their communities</strong> are invited to bring their collections into Gladiator. This ensures that every NFT project integrated into the game reflects strong values, creative passion, and a commitment to delivering real utility to players.</p>

                    <h2 className="gladiator-arena">EVM Compatibility</h2>
                    <p className='mt0 mb4'>
                        Gladiator is built to be fully EVM compatible, integrating seamlessly with NFT collections. This compatibility adds real utility to the work of dedicated creators, enabling their communities to leverage interactive gameplay and enhanced ownership benefits all powered by blockchain technology.
                    </p>

                    <h2 className="gladiator-arena">Public Good</h2>
                    <p className='mt0 mb4'>Gladiator isn't just about gaming — it's about giving back. Our goal is to give back to charitable organizations, helping to make a difference in the real world. This is possible by sharing proceeds from microtransactions within the game. Our areas of support include:</p>
                    <ul className='mb4'>
                        <li>Animal Sanctuaries - Providing care and protection for rescued animals.</li>
                        <li>Cancer Support Groups - Assisting those affected by cancer with resources and aid.</li>
                        <li>Children's Mental Health - Supporting programs that promote mental well-being for kids.</li>
                    </ul>
                    <p className='mb4'>By playing Gladiator, you're not just enjoying an innovative game—you're contributing to a greater cause.</p>

                    <h2 className="gladiator-arena">Why Play Gladiator?</h2>
                    <ul className='mb4'>
                        <li>True Ownership – Your cards are NFTs, meaning you can collect, trade, or sell them.</li>
                        <li>Fast & Fun Gameplay – Easy to pick up, but with deep strategy for competitive play.</li>
                        <li>Seamless Web3 Integration – No complex setups, just smooth blockchain-powered gaming.</li>
                        <li>Community-Driven – Tournaments, player-created content, and a thriving ecosystem.</li>
                    </ul>

                    <h2 className="gladiator-arena">Thank You</h2>
                    <p>If you made it this far... thank you for being here. The future is bright!</p>

                </div>
            </div>
        </div>
    );
};

export default About;
