// Config
import { CollectionDetails, Network } from "./collections.interface";

/**
 * Get the network ID for a given network
 * @param network Collection Network
 * @returns Collection Network ID
 */
export function getNetworkId(network: Network): number {
    const networkIdMap: { [key in Network]: number } = {
        [Network.Mainnet]: 1,
        [Network.Sepolia]: 11155111,
        [Network.Base]: 8453,
        [Network.BaseSepolia]: 84532,
        [Network.Abstract]: 2741
        // Add other network IDs as needed
    };

    return networkIdMap[network];
}

export function mainnet_network_to_readable(network: Network): string {
    switch (network) {
        case Network.Mainnet:
            return "Ethereum";
        case Network.Sepolia:
            return "Sepolia";
        case Network.Base:
            return "Base";
        case Network.BaseSepolia:
            return "Base Sepolia";
        case Network.Abstract:
            return "Abstract";
        default:
            return "Unknown Network";
    }
}

/**
 * Get the collection details via the collection's address
 * @param address Address of the collection
 * @returns Collection Details
 */
export function getCollectionByAddress(address: string, collections: CollectionDetails[]): CollectionDetails {
    return collections.find((c) => c.mainnet_address === address)!;
}

/**
 * Get the collection details via the collection's UUID
 * @param uuid UUID of the collection
 * @returns Collection Details
 */
export function getCollectionByID(uuid: string, collections: CollectionDetails[]): CollectionDetails {
    return collections.find((c) => c.uuid === uuid)!;
}
