import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

// Third Web
import { createThirdwebClient } from "thirdweb";
import { base } from "thirdweb/chains";
import { AutoConnect, ThirdwebProvider } from "thirdweb/react";
import { inAppWallet } from 'thirdweb/wallets';

// Configuration
const clientId = "4e8c81182c3709ee441e30d776223354";
const factoryAddress = "0xD771615c873ba5a2149D5312448cE01D677Ee48A";
const accountAbstraction = {
  factoryAddress,
  chain: base,
  gasless: true,
};

const supportedWallets = [
  inAppWallet({ smartAccount: accountAbstraction })
];

// Create client
const client = createThirdwebClient({ clientId });

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<ThirdwebProvider><AutoConnect client={client} wallets={supportedWallets} /><App /></ThirdwebProvider>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
