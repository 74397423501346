import { LocalCollectionProperties } from "../../config/collections.interface";
import { CollectionSlug, spacesStorage } from "../../config/collections";
import { BackdropTypes } from "../../types/Backdrops.type";
import { EquipmentType } from "../../interfaces/Equipment.interface";

// Collection Slug
const slug: CollectionSlug = CollectionSlug.Reds;

// Object Path
export const objectPath_reds = `${spacesStorage}/${slug}`;
const frames = `${objectPath_reds}/shop/cardframe`;
// const wearables = `${objectPath_reds}/shop/wearable`;
const backdrops = `${objectPath_reds}/backdrops`;

const collection: LocalCollectionProperties = {
    slugLocal: CollectionSlug.Reds,
    partner: true,
    logo: `${objectPath_reds}/welcome/logo.png`,
    logoSmall: `${objectPath_reds}/welcome/logo-small.png`,
    bgImage: `${objectPath_reds}/welcome/bg.png`,
    cardImages: [
        `${objectPath_reds}/welcome/card-2.png`, 
        `${objectPath_reds}/welcome/card-1.png`, 
        `${objectPath_reds}/welcome/card-3.png`
    ],
    gameplay: {
        cardFront: `${objectPath_reds}/card/front.png`,
        cardBack: `${objectPath_reds}/card/back.png`
    },
    colosseumBackdrop: `${objectPath_reds}/backdrops/RedHabitat.jpg`,
    backdrops: [
    ],
    equipment: [
    ]
};

export default collection;