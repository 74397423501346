import { BackdropData } from "../interfaces/Backdrop.interface";
import { Equipment } from "../interfaces/Equipment.interface";
import { CollectionRow } from "../supabase/types";
import { CollectionSlug } from "./collections";

export enum Network {

    /**
     * ETH Mainnet
     */
    Mainnet = 'mainnet',

    /**
     * Sepolia Testnet
     */
    Sepolia = 'sepolia',

    /**
     * Base Network
     */
    Base = 'base-mainnet',

    /**
     * Base Sepolia
     */
    BaseSepolia = 'base-sepolia',

    /**
     * Abstract
     */
    Abstract = 'abstract',

}

export interface LocalCollectionProperties {

    /**
     * Collection Slug
     */
    slugLocal: CollectionSlug;

    /**
     * Collection is a partner collection
     */
    partner?: boolean;

    /**
     * Colleciton Transparent Logo
     */
    logo?: string;

    /**
     * Collection Small Logo (Appears top center of app for partners)
     */
    logoSmall?: string;

    /**
     * Primary interface background
     */
    colosseumBackdrop?: string;

    /**
     * Collection Background Image
     */
    bgImage?: string;

    /**
     * Welcome Component
     */
    welcomeComponent?: JSX.Element;

    /**
     * Array of image URLs: [left, center, right]
     */
    cardImages?: string[];

    /**
     * Gameplay image assets
     */
    gameplay?: {

        /**
         * Card front image
         */
        cardFront?: string;

        /**
         * Card back image
         */
        cardBack?: string;

    }

    backdrops?: BackdropData[];

    /**
     * Equipment
     */
    equipment?: Equipment[];

}

export type CollectionDetails = LocalCollectionProperties & CollectionRow;
