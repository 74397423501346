// React
import React, { useEffect } from 'react';

// Interfaces
import { CollectionDetails } from '../../config/collections.interface';
import { CollectionSlug } from '../../config/collections';

// Components
import ChainLogo from './ChainLogo';

// Images
import { useParams } from 'react-router';
import CollectionDetailsAndLinks from './CollectionDetailsAndLinks';

interface CollectionHeroProps {
    collection: CollectionSlug;
    collectionDetails: CollectionDetails;
}

const CollectionHero: React.FC<CollectionHeroProps> = ({
    collection,
    collectionDetails,
}) => {

    const { page } = useParams<{ page: string }>();
    const [hideHero, setHideHero] = React.useState(false);

    useEffect(() => {
        if (page > '1') {
            setHideHero(true);
        } else {
            setHideHero(false);
        }
    }, [page]);

    return (
        <div
            className="container-with-blur relative animate__animated animate__fadeIn"
            style={{
                backgroundImage: `url('${collectionDetails?.bgImage}')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                maxWidth: '1150px',
                margin: '0 auto',
                padding: '1rem',
                display: hideHero ? 'none' : 'block',
            }}
        >
            <div className="logo flex justify-center items-center">
                {collectionDetails?.logo ? (
                    <img src={collection
                        ? collectionDetails?.logo
                        : collectionDetails?.logo
                    } alt="" className='logo' style={{ width: '100%', height: '200px', objectFit: 'contain' }} />
                ) : (
                    <h2 className="logo gladiator-arena f4">{collectionDetails?.name}</h2>
                )}
            </div>
            <CollectionDetailsAndLinks collection={collection} collectionDetails={collectionDetails} />

        </div>
    );
};

export default CollectionHero;