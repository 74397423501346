import { AbilityLib, AbilityType } from "../../../interfaces/Ability.interface";
import { bushidoBun, carrotGuard, hatTrick, thumperBomb } from "./fx";
import { ReactComponent as BushidoBun } from '../../../collections/buns/abilities/buns_bushido-bun.svg';
import { ReactComponent as CarrotGuard } from '../../../collections/buns/abilities/buns_carrot-guard.svg';
import { ReactComponent as HatTrick } from '../../../collections/buns/abilities/buns_hat-trick.svg';
import { ReactComponent as ThumberBomb } from '../../../collections/buns/abilities/buns_thumper-bomb.svg';

const abilities: AbilityLib[] = [
    {
        "name": "Thumper Bomb",
        "power": 55,
        "energy": 2,
        "group": false,
        "type": AbilityType.Attack,
        "self": false,
        "description": "Launch a powerful projectile, causing significant damage to a single target.",
        "cssClass": "thumper-bomb",
        "icon": <ThumberBomb />,
        "method": thumperBomb,
        "sound": {
            "title": "Thumper Bomb",
            "url": "https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/collections/buns/fx/thumper-bomb/SFX%20-%20Thumper%20Bomb_mixdown.mp3",
            "soundsLike": "fuse, throw and explosion"
        }
    },
    {
        "name": "Bushido Bun",
        "power": 40,
        "energy": 2,
        "group": false,
        "type": AbilityType.Attack,
        "self": false,
        "description": "Deliver a swift melee strike with the discipline of a warrior.",
        "cssClass": "bushido-bun",
        "icon": <BushidoBun />,
        "method": bushidoBun,
        "sound": {
            "title": "Bushido Bun",
            "url": "https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/collections/buns/fx/bushido-bun/SFX%20-%20Bushido%20Bun_mixdown.mp3",
            "soundsLike": "multiple sword swing and impact"
        }
    },
    {
        "name": "Hat Trick",
        "power": 35,
        "energy": 4,
        "group": true,
        "type": AbilityType.Attack,
        "self": false,
        "description": "A flurry of strikes affecting multiple enemies in a group.",
        "cssClass": "hat-trick",
        "icon": <HatTrick />,
        "method": hatTrick,
        "sound": {
            "title": "Hat Trick",
            "url": "https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/collections/buns/fx/hat-trick/SFX%20-%20Hat%20Trick_mixdown.mp3",
            "soundsLike": "magic eletric flute"
        }
    },
    {
        "name": "Carrot Guard",
        "power": 30,
        "energy": 2,
        "group": false,
        "type": AbilityType.HealDefense,
        "self": true,
        "description": "Summon a carrot shield to restore health and defend against incoming attacks.",
        "cssClass": "carrot-guard",
        "icon": <CarrotGuard />,
        "method": carrotGuard,
        "sound": {
            "title": "Carrot Guard",
            "url": "https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/collections/buns/fx/carrot-guard-v2/SFX%20-%20Carrot%20Guard_mixdown.mp3",
            "soundsLike": "carrot bite crunch and healing"
        }
    }
];

export default abilities;