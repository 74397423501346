// Config
import { CollectionSlug } from '../config/collections';

// React
import { UserContext } from '../Context';
import { useContext, useEffect, useState } from 'react';
import { PlayContext, PlayState } from './PlayContext';
import { useIsLargeScreen } from '../hooks/useIsLargeScreen';

// Ionic
import { IonBadge, IonButton, IonContent, IonFooter, IonIcon, IonItem, IonLabel, IonList, IonPage, IonPopover, IonSegment, IonSegmentButton, IonSpinner, useIonAlert, useIonViewDidEnter } from '@ionic/react';
import { addCircleOutline, arrowUpSharp, chevronBack, chevronDown, chevronForward, informationCircle, logOut, removeCircleOutline } from 'ionicons/icons';

// Interfaces
import User, { UserState } from '../interfaces/User.interface';
import { CollectionDetails } from '../config/collections.interface';

// Images
import { ReactComponent as GetReadyPlaceholder } from '../images/get-ready-placeholder.svg';
import swordsIcon from '../images/icons/swords.svg';
import collectionIcon from '../images/icons/cards.svg';
import gladiatorHelmet from '../images/icons/gladiator-helmet.svg';

// Components
import PlayerCardDisplay from '../components/play/PlayerCardDisplay';
import Power from '../components/play/Power';
import CardEquipment from '../components/shop/CardEquipment';
import ParticleOverlay from '../components/ui/ParticleOverlay';

// Services
import { leave, ready } from '../services/GameServer';

// Styles
import './GetReady.scss';

// Utils
import { tilt } from '../Utils';

// Utils
import { getCollectionByID } from '../config/utils';
import { EquipmentType } from '../interfaces/Equipment.interface';

// Images

const GetReady: React.FC = () => {

    // User Context
    const { 
        router,
        user, 
        collections, 
        shopItems,
        waitModal,
        setWaitModal,
    } = useContext<UserState>(UserContext);

    // Play Context
    const { 

        // State
        room,
        loadingToken,
        prepareToken,
        frame,
        wearable,
        myTokens,
    
        // Methods
        setLoadingToken,
        setPrepareToken,
        setFrame,
        setWearable,
        toggleTokenSelect,
        isReady,
        isEquipmentAttachedToAnyTeamMember,
        isThisEquipmentAttachedToThisToken,
        resetGame,
    
      }: PlayState = useContext(PlayContext);

    // Collection
    const [collection, setCollection] = useState<CollectionSlug | null>();
    const [collectionDetails, setCollectionDetails] = useState<CollectionDetails | undefined>();

    // Pagination
    const [collectionPageSize] = useState<number>(6);
    const [collectionPage, setCollectionPage] = useState<number>(1);

    // Equipment
    const [equipment, setEquipment] = useState<EquipmentType>(EquipmentType.FRAMES);
    const [showPopover, setShowPopover] = useState(false);
    const [mobileView, setMobileView] = useState<'collection' | 'equip' | 'team'>(`collection`);
    
    // Player Ready
    const [playerReady, setPlayerReady] = useState<boolean>(false);

    /**
     * Large Screen Detection
     */
    const isLarge = useIsLargeScreen();

    /**
     * Ion Alert
     */
    const [ presentAlert, dismissAlert ] = useIonAlert();

    /**
     * Handle popover dismiss
     */
    const handlePopoverDismiss = () => {
        setShowPopover(false);
    };

    // Initialize the tilt.js effect
    useEffect(() => {       
        setTimeout(() => {
            tilt('tilt-js-get-ready');
        }, 250);
    }, [prepareToken?.frame?.id, frame?.id, wearable?.id, loadingToken, myTokens, prepareToken]);

    /**
     * Equipment Popover Text
     */
    const NoEquipmentPopoverText = () => {
        return (
            <div className="ph2 text-drop-shadow b pt-serif tc">
                <p className='lh-copy'>
                    Win battles, spend in-game currency (Gladii), ETH, or USDC to purchase battle-enhancing upgrades.
                </p>
                <p className='lh-copy'>Stay tuned for updates, equipment will be released over time.</p>
            </div>
        )
    }

    /**
     * Ready Player
     */
    const playerIsReady = (user: User, room: any, myTokens: any, type: 'owned' | 'supplemented') => {
        if (!playerReady) {
            setWaitModal(true);
            setPlayerReady(true);
            console.log('Player is ready');
            setTimeout(() => {
                console.log('Player is ready');
                ready(user, room, myTokens, type);
            }, 1000)
        } else {
            console.log('Player is already ready');
        }
    }

    // Collection search listener
    useEffect(() => {

        setPrepareToken(null)
        setFrame(null)
        setWearable(null)

        /**
         * Set the collection details based on the selected collection
         */
        const isolateCollection = collections?.find((c) => c.slug === collection);
        if (isolateCollection) {

            // Set the collection details
            setCollectionDetails(isolateCollection);

            // Reset pagination if the collection changes
            setCollectionPage(1);

        }

    }, [user.collectibles, collectionPageSize, collection, collections, setFrame, setWearable, setPrepareToken]);

    // Re-enable tilt
    useEffect(() => {
        tilt('tilt-js-get-ready');
    }, [collectionPage])

    return (
        <IonPage className="gladiator-page get-ready">
            <IonContent fullscreen className='transparent'>
                <div className="w-100 h-100 relative z-4">
                    <ParticleOverlay />
                    <div className="page-content">
                        <div className="relative row scrollable-page-content">
                            <div className="column small-12 relative z-4 flex flex-wrap justify-center">

                                {/* Gladiator Cards */}
                                {isLarge || mobileView === 'collection' ? (
                                <div className={`gladiator-cards column small-12 large-3 ph0 ph3-l`}>

                                    {/* Column title */}
                                    <div className="flex justify-center justify-between-l bb b--white-20 mb1">
                                        <h2 className="gladiator-arena w-100 tc tl-l mv0 text-drop-shadow pt2">
                                            <span className="pt3 truncate">Collectibles</span>
                                        </h2>
                                    </div>

                                    {/* Select Dropdown */}
                                    <div className='flex justify-center justify-start-l'>
                                        <IonButton
                                            color={'transparent'}
                                            fill={'clear'}
                                            onClick={() => setShowPopover(true)}
                                            id='collection-selector'
                                        >
                                            <span className="dib pt0 mt0 pt-serif b white f5 text-drop-shadow">{collectionDetails?.name ? `${collectionDetails.name} (${user?.collectibles.filter(c => c.collection === collection).length})` : 'Choose Collection'}</span>
                                            <IonIcon icon={chevronDown} className="ml2 white" />
                                        </IonButton>

                                        <IonPopover
                                            isOpen={showPopover}
                                            onDidDismiss={handlePopoverDismiss}
                                            trigger='collection-selector'
                                        >
                                            <IonList>
                                                {collections.map((c, i) => (
                                                    <IonItem
                                                        button
                                                        key={`opt_${i}`}
                                                        onClick={() => {
                                                            setCollection(null);
                                                            setCollection(c.slugLocal);
                                                            setTimeout(() => {
                                                                handlePopoverDismiss();
                                                            }, 100);
                                                        }}
                                                    >
                                                        <span className="pt3 db gladiator-arena">{`${c.name} (${user?.collectibles.filter(cl => cl.collection === c.slug).length})`}</span>
                                                    </IonItem>
                                                ))}
                                            </IonList>
                                        </IonPopover>
                                    </div>

                                    {/* Pagination */}
                                    {(collectionDetails) && 
                                        (user?.collectibles
                                            // Filter the collectibles by the selected collection
                                            .filter(c => c.collection === collection)
                                            // Omit the collectibles that are already in the team
                                            .filter(c => !myTokens.find(t => t.token_id === c.token_id))
                                            // Check if the number of collectibles is greater than the page size
                                            .length > collectionPageSize
                                        )
                                         ? (
                                        <div className="column small-12 flex justify-between items-center content-center pr4">
                                            <IonButton id='prev-page' color={`primary`} className='relative z-2' size='small'

                                                /**
                                                 * Disable the previous page button if the user is on the first page
                                                 */
                                                disabled={collectionPage <= 1}
                                                onClick={() => {
                                                    if (collectionPage > 1) {
                                                        setCollectionPage(collectionPage - 1);
                                                    }
                                                }}
                                            >
                                                <span className="show-for-sr">Previous Page</span>                                           
                                                <IonIcon
                                                    icon={chevronBack}
                                                    color='dark'
                                                    className='animate__animated animate__zoomIn'
                                                    size='small'
                                                    style={{
                                                        marginInlineStart: 0,
                                                        marginInlineEnd: 0,
                                                    }}
                                                />
                                            </IonButton>

                                            <IonBadge 
                                                color="primary" 
                                                className="page-badge" 
                                                onClick={() => {
                                                    const totalPages = Math.ceil(user?.collectibles.filter(c => c.collection === collection).length / collectionPageSize);
                                                    const nextPage = collectionPage < totalPages ? collectionPage + 1 : 1;
                                                    setCollectionPage(nextPage);
                                                }}
                                            >
                                                {collectionPage} / {Math.ceil(
                                                    // Divide the number of collectibles by the page size to get the number of pages
                                                    user?.collectibles
                                                        // Omit the collectibles that are already in the team
                                                        ?.filter(c => !myTokens.find(t => t.token_id === c.token_id))
                                                        ?.filter(c => c.collection === collection).length / collectionPageSize
                                                )}
                                            </IonBadge>

                                            <IonButton id='next-page' color={`primary`} className='relative z-2' size='small'

                                                /**
                                                 * Disable the next page button if the user is on the last page
                                                 */
                                                disabled={
                                                    user?.collectibles
                                                        // Omit the collectibles that are already in the team
                                                        ?.filter(c => !myTokens.find(t => t.token_id === c.token_id))
                                                        ?.filter(c => c.collection === collection).length <= collectionPageSize * collectionPage
                                                }
                                                onClick={() => {
                                                    if (user?.collectibles.filter(c => c.collection === collection).length > collectionPageSize * collectionPage) {
                                                        setCollectionPage(collectionPage + 1);
                                                    }
                                                }}
                                            >
                                                <span className="show-for-sr">Next Page</span>                                           
                                                <IonIcon
                                                    icon={chevronForward}
                                                    color='dark'
                                                    className='animate__animated animate__zoomIn'
                                                    size='small'
                                                    style={{
                                                        marginInlineStart: 0,
                                                        marginInlineEnd: 0,
                                                    }}
                                                />
                                            </IonButton>
                                        </div>                                        
                                    ) : null}

                                    {/* Collectibles */}
                                    <div className={`column small-12 relative z-4 flex flex-wrap justify-center items-center characters content-start `}>
                                        {user?.collectibles
                                            // Filter the collectibles by the selected collection
                                            ?.filter(c => c.collection === collection)
                                            // Omit the collectibles that are already in the team
                                            ?.filter(c => !myTokens.find(t => t.token_id === c.token_id))
                                            // Paginate the collectibles
                                            ?.slice((collectionPage - 1) * collectionPageSize, collectionPage * collectionPageSize)
                                            ?.map((m, i) => {

                                            if (!isReady(m)) {
                                                return (
                                                    <div
                                                        key={`collectible-${m.token_id}-${m.collection}-${i}`}
                                                        className='player-card-container pa3 relative'
                                                    >
                                                        <PlayerCardDisplay card={m} click={() => {
                                                            if (prepareToken && prepareToken.token_id === m.token_id) {
                                                                setPrepareToken(null);
                                                            } else {
                                                                setCollection(m.collection as CollectionSlug);
                                                                setLoadingToken(true);
                                                                setPrepareToken(null);
                                                                setTimeout(() => {
                                                                    setLoadingToken(false);
                                                                    setPrepareToken(m);
                                                                }, 100)
                                                            }
                                                        }} />
                                                    </div>
                                                )
                                            } else {
                                                return null
                                            }
                                            
                                        })}

                                        {collectionDetails && user?.collectibles.filter(c => c.collection === collection).length <= 0 ? (
                                            <div className="animate__animated animate__fadeIn w-100 tc pr3 pl2">
                                                <p className="pt-serif text-drop-shadow b lh-copy">
                                                    No collectibles available for the {collectionDetails?.name} collection.
                                                    <IonIcon 
                                                        icon={informationCircle} 
                                                        className="ml2" 
                                                        id="no-frames-tooltip" 
                                                    />
                                                </p>
                                                <IonPopover
                                                    trigger="no-frames-tooltip"
                                                    triggerAction="hover"
                                                    className="popover-tooltip"
                                                >
                                                    <NoEquipmentPopoverText />
                                                </IonPopover>
                                            </div>
                                        ) : null}

                                        {/* Display choose collectibles message if no collection has been selected */}
                                        {collectionDetails ? null : (
                                            <div className="animate__animated animate__fadeIn w-100 pl3 begin-message">
                                                <div className='mt2'>
                                                <IonIcon icon={arrowUpSharp} className="ml2" size='large' />
                                                <p className="pt-serif text-drop-shadow b lh-copy f4 mt2">
                                                    To begin, select a collection<br />from the dropdown above.
                                                </p>
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                </div>
                                ) : null}

                                {/* Gladiator Preview */}
                                {isLarge || mobileView === 'equip' ? (
                                <div className={`gladiator-preview column small-12 large-6 tc flex flex-column`}>

                                    <div className="card-preview-container flex justify-center items-center relative">

                                        {/* Select character message */}
                                        {prepareToken === null && !loadingToken ? (
                                            <div className="animate__animated animate__zoomIn relative choose-wisely">
                                                <div className="relative player-card">
                                                    <GetReadyPlaceholder className='placeholder' />
                                                    <h3 className="gladiator-arena w5">Choose your combatant wisely</h3>
                                                </div>
                                            </div>
                                        ) : null}

                                        {/* Loading spinner */}
                                        {loadingToken ? (
                                            <div className="animate__animated animate__fadeIn text-drop-shadow-dark">
                                                <IonSpinner name="crescent" color={'dark'} />
                                            </div>
                                        ) : null}

                                        {/* Card Preview */}
                                        {prepareToken?.token_id ? (
                                            <div className="animate__animated animate__zoomIn">
                                                <PlayerCardDisplay 
                                                    card={prepareToken} 
                                                    clickFlip
                                                    stats
                                                />
                                            </div>
                                        ) : null}

                                    </div>
                                </div>
                                ) : null}

                                {/* Gladiator Equip */}
                                {isLarge || mobileView === 'team' ? (
                                <div className={`gladiator-equip column small-12 large-3 tr flex flex-column justify-start relative`}>

                                    <div className="equipment">
                                        {/* Equipment */}
                                        <div className="flex justify-center justify-between-l bb b--white-20 mb3">
                                            <h2 className="gladiator-arena w-100 tc tl-l mv0 text-drop-shadow pt2">
                                                {/* <span className="f6">Select</span><br /> */}
                                                <span className="pt3 truncate">Equipment</span>
                                            </h2>
                                            {/* <div className='tl w-50 dn'>
                                                <IonSearchbar
                                                    autocapitalize={'false'}
                                                    showClearButton="always"
                                                    placeholder="Search equipment"
                                                    mode='ios'
                                                    color={'transparent'}
                                                ></IonSearchbar>
                                            </div> */}
                                        </div>
                                        <div>
                                            <IonSegment
                                                value={equipment}
                                                onIonChange={(e) => {
                                                    const value = e.detail.value as string;
                                                    const lowercaseValue = value.toLowerCase();
                                                    const equipmentType = lowercaseValue as EquipmentType;
                                                    setEquipment(equipmentType)
                                                }}
                                                mode='ios'
                                                color={'primary'}
                                            >
                                                <IonSegmentButton value={EquipmentType.FRAMES}>
                                                    <IonLabel><span className="pt-serif dib b f5 ttu">Frames</span></IonLabel>
                                                </IonSegmentButton>
                                                <IonSegmentButton value={EquipmentType.WEARABLES}>
                                                    <IonLabel><span className="pt-serif dib b f5 ttu">Wearables</span></IonLabel>
                                                </IonSegmentButton>
                                            </IonSegment>
                                        </div>
                                        <div className='column small-12 relative z-4 flex equipment pv2 mb2'>
                                            {collection ? (
                                                <>
                                                    {/* Display frames if there are any owned */}
                                                    {equipment === EquipmentType.FRAMES ? shopItems
                                                        .filter(item => item.type === EquipmentType.FRAMES)
                                                        .filter(s => getCollectionByID(s.collection!, collections)?.slug === collection)
                                                        .map((item, i) => {
                                                            const alreadyAttached = isEquipmentAttachedToAnyTeamMember(item) && (prepareToken && !isThisEquipmentAttachedToThisToken(prepareToken, item));
                                                            const attachedToThisToken = prepareToken ? isThisEquipmentAttachedToThisToken(prepareToken, item) : false;
                                                            return (
                                                                <CardEquipment 
                                                                    key={`collectible-purchase-${item.id}_${prepareToken?.id}`} 
                                                                    shopItem={item} 
                                                                    thumbnail 
                                                                    alreadyAttached={alreadyAttached} 
                                                                    attachedToThisToken={attachedToThisToken}
                                                                />
                                                            )
                                                    }) : null}

                                                    {/* Display no frames message if they have not yet purchased or made available */}
                                                    {equipment === EquipmentType.FRAMES && shopItems
                                                        .filter(item => item.type === EquipmentType.FRAMES)
                                                        .filter(s => getCollectionByID(s.collection!, collections)?.slug === collection).length <= 0 ? (
                                                            <div className="animate__animated animate__fadeIn w-100 tc pr3 pl2">
                                                                <p className="pt-serif text-drop-shadow b lh-copy">
                                                                    No frames available for the {collectionDetails?.name} collection.
                                                                    <IonIcon 
                                                                        icon={informationCircle} 
                                                                        className="ml2" 
                                                                        id="no-frames-tooltip" 
                                                                    />
                                                                </p>
                                                                <IonPopover
                                                                    trigger="no-frames-tooltip"
                                                                    triggerAction="hover"
                                                                    className="popover-tooltip"
                                                                >
                                                                    <NoEquipmentPopoverText />
                                                                </IonPopover>
                                                            </div>
                                                        ) : null}

                                                    {/* Display wearables if there are any owned */}
                                                    {equipment === EquipmentType.WEARABLES ? shopItems
                                                        .filter(item => item.type === EquipmentType.WEARABLES)
                                                        .filter(s => getCollectionByID(s.collection!, collections)?.slug === collection)
                                                        .map((item, i) => {
                                                            const alreadyAttached = isEquipmentAttachedToAnyTeamMember(item) && (prepareToken && !isThisEquipmentAttachedToThisToken(prepareToken, item));
                                                            const attachedToThisToken = prepareToken ? isThisEquipmentAttachedToThisToken(prepareToken, item) : false;
                                                            return (
                                                                <CardEquipment 
                                                                    key={`collectible-purchase-${item.id}_${prepareToken?.id}`} 
                                                                    shopItem={item} 
                                                                    thumbnail 
                                                                    alreadyAttached={alreadyAttached} 
                                                                    attachedToThisToken={attachedToThisToken}
                                                                />
                                                            )
                                                    }) : null}

                                                    {/* Display no wearables message if they have not yet purchased or made available */}
                                                    {equipment === EquipmentType.WEARABLES && shopItems
                                                        .filter(item => item.type === EquipmentType.WEARABLES)
                                                        .filter(s => getCollectionByID(s.collection!, collections)?.slug === collection).length <= 0 ? (
                                                            <div className="animate__animated animate__fadeIn w-100 tc pr3 pl2">
                                                                <p className="pt-serif text-drop-shadow b lh-copy">
                                                                    No wearables available for the {collectionDetails?.name} collection.
                                                                    <IonIcon 
                                                                        icon={informationCircle} 
                                                                        className="ml2" 
                                                                        id="no-frames-tooltip" 
                                                                    />
                                                                </p>
                                                                <IonPopover
                                                                    trigger="no-frames-tooltip"
                                                                    triggerAction="hover"
                                                                    className="popover-tooltip"
                                                                >
                                                                    <NoEquipmentPopoverText />
                                                                </IonPopover>
                                                            </div>
                                                        ) : null}

                                                </>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="team relative z-9 mt3">
                                        {/* Team */}
                                        <div className="flex justify-between bb b--white-20">
                                            <h2 className="gladiator-arena w-100 tl mv0 flex items-center text-drop-shadow">
                                                <span className="pt3 truncate">Your Team</span>
                                            </h2>
                                            <div>
                                                <div className="power-container-get-ready">
                                                    <Power tokens={myTokens} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`w-100 team-container team-count-${myTokens?.length}`}>
                                            {/* Loop through my tokens */}
                                            {myTokens.map((token, i) => {
                                                return (
                                                    <div 
                                                        key={`tokenbtn-${i}`} 
                                                        className={`team-member ${prepareToken && prepareToken.token_id === token.token_id ? `selected` : ``} `}>
                                                        <PlayerCardDisplay card={token} click={() => {
                                                            if (prepareToken && prepareToken.token_id === token.token_id) {
                                                                setPrepareToken(null)
                                                            } else {
                                                                setCollection(token.collection as CollectionSlug)
                                                                setLoadingToken(true)
                                                                setPrepareToken(null)
                                                                setTimeout(() => {
                                                                    setLoadingToken(false)
                                                                    setPrepareToken(token)
                                                                }, 300)
                                                            }
                                                        }} />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>

                                </div>
                                ) : null}

                            </div>
                        </div>
                    </div>
                </div>
                <div id="tilt-js-get-ready" />
            </IonContent>


            <IonFooter>
                <div className="w-100 flex justify-around items-center bottom-nav">

                    <IonButton size='large' onClick={(e) => {
                        presentAlert({
                            mode: 'ios',
                            header: 'Confirm',
                            message: 'Are you sure you want to leave?',
                            buttons: [
                                {
                                    text: 'Cancel',
                                    role: 'cancel',
                                    handler: () => {
                                        dismissAlert();
                                    }
                                },
                                {
                                    text: 'Leave',
                                    handler: () => {
                                        if (room) {
                                            leave(user, room).then(() => {
                                                resetGame(false);
                                                router?.push('/');
                                            });
                                        }
                                    }
                                }
                            ]
                        });
                    }} color={'transparent'}>
                        <IonIcon src={logOut} className='mr2-l rotate-180' size='large' />
                        <span className="button-text f5 show-for-large">Retreat</span>
                    </IonButton>

                    <IonButton
                        color={'transparent'}
                        className={`${mobileView === 'collection' ? 'selected' : ''} hide-for-large`}
                        onClick={() => {
                            setMobileView('collection')
                        }}
                    >                        
                        <IonIcon src={gladiatorHelmet} className='mr2-l' size='large' />
                        <span className="button-text f5 show-for-large">Collection</span>
                    </IonButton>

                    <IonButton
                        color={'transparent'}
                        disabled={(myTokens.length >= 3 && !isReady(prepareToken)) || !prepareToken}
                        className={`${prepareToken && myTokens.length >= 3 && !isReady(prepareToken) ? `o-40 pointer-events-none` : ``}`}
                        onClick={() => {
                            toggleTokenSelect(prepareToken)
                        }}
                    >
                        {isReady(prepareToken) ? (
                            <>
                                <IonIcon icon={removeCircleOutline} className='mr2-l' size='large' />
                                <span className="button-text f5 show-for-large mr3-l">Remove</span>
                            </>
                        ) : (
                            <>
                                <IonIcon icon={addCircleOutline} className='mr2-l' size='large' />
                                <span className="button-text f5 show-for-large mr3-l">Add</span>
                            </>
                        )}
                        {prepareToken ? (
                            <IonBadge color='primary' class='ml1'>1</IonBadge>
                        ) : null}
                    </IonButton>

                    <IonButton
                        color={'transparent'}
                        className={`relative ${mobileView === 'team' ? 'selected' : ''} hide-for-large`}
                        onClick={() => {
                            setMobileView('team')
                        }}
                    >
                        <IonIcon src={collectionIcon} className='mr2-l' size='large' />
                        <span className="button-text f5 show-for-large mr3-l">Team</span>
                        <IonBadge color='primary' class='ml1'>{myTokens.length}</IonBadge>
                    </IonButton>

                    <IonButton 
                        color={'primary'} 
                        className='mr0'
                        disabled={myTokens.length === 0}
                        onClick={() => {
                            if (room) {
                                // If we have less than three tokens, use presentAlert to ask the user if they want to supplement their team with random tokens
                                if (myTokens.length < 3) {
                                    presentAlert({
                                        mode: 'ios',
                                        header: 'Summon Gladiators?',
                                        message: 'Your team has fewer than three gladiators. Would you like to summon random gladiators to join your team?',
                                        buttons: [
                                            {
                                                text: 'Cancel',
                                                role: 'cancel',
                                                handler: () => {
                                                    dismissAlert();
                                                }
                                            },
                                            {
                                                text: 'Go it alone',
                                                handler: () => {
                                                    playerIsReady(user, room, myTokens, 'owned');
                                                }
                                            },
                                            {
                                                text: 'Team up',
                                                handler: () => {
                                                    playerIsReady(user, room, myTokens, 'supplemented');
                                                }
                                            }
                                        ]
                                    });
                                } else {
                                    playerIsReady(user, room, myTokens, 'owned');
                                }
                            }
                        }}
                        id="ready-button"
                    >
                        <span className="ph1 flex items-center">
                            <IonIcon icon={swordsIcon} className='mr2-l' size='large' />
                            <span className="button-text show-for-large">Ready</span>
                        </span>
                    </IonButton>

                </div>
            </IonFooter>
        </IonPage>
    );
};

export default GetReady;
