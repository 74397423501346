import { CollectionSlug, spacesStorage } from "../../config/collections";
import { LocalCollectionProperties } from "../../config/collections.interface";
import { BackdropTypes } from "../../types/Backdrops.type";

// Collection Slug
const slug: CollectionSlug = CollectionSlug.Bufficorns;

// Object Path
export const objectPath_bufficorns = `${spacesStorage}/${slug}`;
// const frames = `${objectPath_bufficorns}/shop/cardframe`;
// const wearables = `${objectPath_bufficorns}/shop/wearable`;
const backdrops = `${objectPath_bufficorns}/backdrops`;

const collection: LocalCollectionProperties = {
    slugLocal: CollectionSlug.Bufficorns,
    partner: true,
    logo: `${objectPath_bufficorns}/welcome/ethdenver-2025-regenerates.png`,
    logoSmall: `${objectPath_bufficorns}/welcome/logo-small.png`,
    bgImage: `${objectPath_bufficorns}/welcome/backdrop-snow.jpg`,
    cardImages: [
        `${objectPath_bufficorns}/welcome/card-2.png`, 
        `${objectPath_bufficorns}/welcome/card-1.png`, 
        `${objectPath_bufficorns}/welcome/card-3.png`
    ],
    gameplay: {
        cardFront: `${objectPath_bufficorns}/card/front.png`,
        cardBack: `${objectPath_bufficorns}/card/back.png`
    },
    backdrops: [
        {
            name: 'Bufficorn Spring',
            slug: BackdropTypes.BufficornSpring,
            background: `${backdrops}/${BackdropTypes.BufficornSpring}/bg.jpg`,
            midground: `${backdrops}/${BackdropTypes.BufficornSpring}/mg.png`,
            foreground: `${backdrops}/${BackdropTypes.BufficornSpring}/fg.png`,
        },
        {
            name: 'Bufficorn Winter Skies',
            slug: BackdropTypes.BufficornWinterSkies,
            background: `${backdrops}/${BackdropTypes.BufficornWinterSkies}/bg.jpg`,
            midground: `${backdrops}/${BackdropTypes.BufficornWinterSkies}/mg.png`,
            foreground: `${backdrops}/${BackdropTypes.BufficornWinterSkies}/fg.png`,
        },
        {
            name: 'Bufficorn Skyline',
            slug: BackdropTypes.BufficornSkyline,
            background: `${backdrops}/${BackdropTypes.BufficornSkylineVapor}/bg.jpg`,
            midground: `${backdrops}/${BackdropTypes.BufficornSkylineVapor}/mg.png`,
            foreground: `${backdrops}/${BackdropTypes.BufficornSkylineVapor}/fg.png`,
        },
        {
            name: 'Bufficorn Meadow',
            slug: BackdropTypes.BufficornMeadow,
            background: `${backdrops}/${BackdropTypes.BufficornMeadow}/bg.jpg`,
            midground: `${backdrops}/${BackdropTypes.BufficornMeadow}/mg.png`,
            foreground: `${backdrops}/${BackdropTypes.BufficornMeadow}/fg.png`,
        },
        {
            name: 'Bufficorn Denver Drive',
            slug: BackdropTypes.BufficornDenverDrive,
            background: `${backdrops}/${BackdropTypes.BufficornDenverDrive}/bg.jpg`,
            midground: `${backdrops}/${BackdropTypes.BufficornDenverDrive}/mg.png`,
            foreground: `${backdrops}/${BackdropTypes.BufficornDenverDrive}/fg.png`,
        },
    ]

};

export default collection;