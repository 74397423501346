import React from 'react';

const BattleMechanics: React.FC = () => {
    return (
        <div className='row mb6'>
            <div className="column small-12 pt-serif">
                <div className="ph3 lh-copy">

                    <p><strong>Gladiator</strong> is a fast-paced, hypercasual, easy-to-play card battler where every match is different. Players bring their NFT gladiators into the arena, but their abilities are <b>randomized</b> at the start of each game, relative to collectible traits, creating <b>wild, dynamic battles</b> where strategy and adaptability are key.</p>

                    <p className='mb4'>Each NFT collection has <b>custom-tailored abilities</b> that reflect its unique theme, making every battle feel fresh and immersive. One match might feature warriors wielding mystical spells, while another could have cybernetic fighters using high-tech weapons.</p>

                    <h2 className="gladiator-arena">How It Works</h2>
                    <ul className='mb4'>
                        <li><strong>Pick Your Gladiator:</strong> Choose tokens from integrated NFT collections before entering battle.</li>
                        <li><strong>Random Abilities:</strong> Each gladiator gets a set of abilities at random, meaning no two battles play out the same.</li>
                        <li><strong>Themed Powers:</strong> Abilities match each collection’s style, creating unique playstyles and strategies.</li>
                        <li><strong>Turn-Based Action:</strong> Players take turns attacking, blocking, and using special moves.</li>
                        <li><strong>Quick Matches:</strong> Battles are designed to be short, intense, and easy to jump into.</li>
                    </ul>

                    <h2 className="gladiator-arena">Game Modes</h2>
                    <ul className='mb4'>
                        <li><strong>Casual Mode:</strong> Jump into a quick match and test your luck.</li>
                        <li><strong>Ranked Battles:</strong> Climb the leaderboard and earn rewards.</li>
                        <li><strong>Tournaments:</strong> Compete in special events for exclusive prizes. Coming soon!</li>
                    </ul>

                    <h2 className="gladiator-arena">Why Play?</h2>
                    <ul className='mb4'>
                        <li><strong>Easy to Play:</strong> Simple mechanics make it fun for everyone.</li>
                        <li><strong>Wild & Unpredictable:</strong> No overpowered decks—every match is a new challenge.</li>
                        <li><strong>Collection-Specific Abilities:</strong> Themed powers make battles feel immersive and unique.</li>
                        <li><strong>True Ownership:</strong> Your cards are NFTs, meaning you can trade, sell, or collect them.</li>
                    </ul>

                    <h2 className="gladiator-arena">Step Into the Arena</h2>
                    <p className='mb4'>Enter the colosseum, embrace the chaos, and battle for glory in <strong>Gladiator</strong>.</p>

                    <h2 className="gladiator-arena">Ongoing Development</h2>
                    <p className='mb4'><strong>Gladiator's battle mechanics and in-game economy are a constant work in progress.</strong> As we refine gameplay balance, introduce new abilities, and adjust economic structures, we remain committed to transparency and player feedback. Expect continuous updates and improvements as the game evolves.</p>

                </div>
            </div>
        </div>
    );
};

export default BattleMechanics;
