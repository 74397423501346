import { objectPath_bufficorns } from "..";
import { applyEffects, calculateAngleAndDistance, calculateTranslation } from "../../../fx/fx-utils";
import { TokenMetadata } from "../../../generated/TokenMetadata";
import { AbilityMode } from "../../../types/Abilities.type";
import { gsap } from "gsap";
import { objectPath_sacredmoths } from "../../sacredmoths";

/**
 * Buff Bash: Card Moves Across Screen
 * @param c1 Card Sending Action
 * @param c2 Target Card
 */
export function buffBash(c1: TokenMetadata, c2: TokenMetadata, mode: AbilityMode) {
    return new Promise<boolean>((resolve) => {

        const source = document.getElementById(`card_${c1.wallet}_${c1.collection}_${c1.token_id}`);
        const target = document.getElementById(`card_${c2.wallet}_${c2.collection}_${c2.token_id}`);
    
        if (source && target) {
            const {
                translateX,
                translateY
            } = calculateTranslation(source, target);
    
            setTimeout(() => {
                source.style.transform = `translate(${translateX}px, ${translateY}px)`;
            }, 500)

            const sourceImage = `${objectPath_bufficorns}/fx/super-bufficorn/animated/superbufficorn_a.png`;
            const targetImage = `${objectPath_sacredmoths}/fx/nebula-pulse/opponent/energy.png`;

            applyEffects('buff-bash', mode, target, source, 1200, 500, 1000, sourceImage, targetImage).then(() => {
                setTimeout(() => {
                    source.style.transform = '';
                    resolve(true)
                }, 250)
            })
    
        }

    })

}

/**
 * Spork Surge
 * @param c1 Card Sending Action
 * @param c2 Cards Receiving Damage
 * @returns Boolean
 */
export function sporkSurge(c1: TokenMetadata, c2: TokenMetadata[], mode: AbilityMode) {
    return new Promise<boolean>((resolve) => {

        const source = document.getElementById(`card_${c1.wallet}_${c1.collection}_${c1.token_id}`);

        if (source && c2) {

            /**
             * Display battle damage effect to the target
             */
            const targets = c2
            .map(c => document.getElementById(`card_${c.wallet}_${c.collection}_${c.token_id}`)).filter(t => t !== null);  // Filter out null values

            const centerStageImage = `${objectPath_bufficorns}/fx/spork-hero/animated/sporkhero_a.png`;
            const targetImage = `${objectPath_sacredmoths}/fx/nebula-pulse/opponent/energy.png`;
            applyEffects('spork-surge', mode, targets as HTMLElement[], undefined, 750, 750, 0, undefined, targetImage).then(() => {
                resolve(true)
            })

            // console.log('targets with attribute', targets)

            /**
             * Insert animation DOM element
             */
            const fxDiv = document.createElement('div');
            fxDiv.className = `fx spork-surge center-stg ${mode}`;
            fxDiv.setAttribute('key', `${Date.now()}`)

            // Append an image inside of the div
            const img = document.createElement('img');
            img.src = `${objectPath_bufficorns}/fx/spork-hero/animated/sporkhero_a.png
            
            `;
            img.alt = 'spork-surge';
            fxDiv.appendChild(img);

            // Append the div to the center stage
            document.querySelector('#center-stage')?.appendChild(fxDiv);

            /**
             * Cleanup the view
             */
            setTimeout(() => {

                /**
                 * Remove the animation
                 */
                document.querySelector('.fx.spork-surge.center-stg')?.remove()

                resolve(true)

            }, 1750)

        }

    })
}

/**
 * Merge Conflict
 * @param c1 Card Sending Action
 * @param c2 Cards Receiving Damage
 * @returns Boolean
 */
export function mergeConflict(c1: TokenMetadata, c2: TokenMetadata[], mode: AbilityMode) {
    return new Promise<boolean>((resolve) => {

        const source = document.getElementById(`card_${c1.wallet}_${c1.collection}_${c1.token_id}`);

        if (source && c2) {

            const sourceImage = `${objectPath_bufficorns}/fx/psychic-bufficorn/animated/psychicbufficorn_a.png`;
            const targetImage = `${objectPath_sacredmoths}/fx/nebula-pulse/opponent/energy.png`;

            /**
             * Display battle damage effect to the target
             */
            const targets = c2
            .map(c => document.getElementById(`card_${c.wallet}_${c.collection}_${c.token_id}`)).filter(t => t !== null);  // Filter out null values
            applyEffects('merge-conflict', mode, targets as HTMLElement[], source, 1500, 500, 1000, sourceImage, targetImage).then(() => {
                source.style.transform = '';
                resolve(true)
            })

        }

    })
}

/**
 * Power Up: Restore the Defense Value of a Selected Card
 * @param c Card selected
 * @returns Boolean
 */
export function sporkWhale(c: TokenMetadata) {
    return new Promise<boolean>((resolve) => {

        const target = document.getElementById(`card_${c.wallet}_${c.collection}_${c.token_id}`);
        const targetImage = `${objectPath_bufficorns}/fx/spork-whale/animated/sporkwhalerobot_a.png`;

        if (target) {
            applyEffects('spork-whale', 'cardAbility', target, undefined, 1750, 850, 100, undefined, targetImage).then(() => {
                resolve(true)
            })
        }

    })
}
