import React from 'react';
import { CollectionSlug } from '../../config/collections';
import { CollectionDetails } from '../../config/collections.interface';
import ChainLogo from './ChainLogo';
import { ReactComponent as WebsiteIcon} from './../../images/icons/icon-website.svg';
import { ReactComponent as XLogo } from './../../images/logos/logo-x.svg';
import { ReactComponent as OpenseaLogo } from './../../images/logos/logo-opensea.svg';
import { ReactComponent as RaribleLogo } from './../../images/logos/logo-rarible.svg';
import { IonIcon } from '@ionic/react';
import { diamond } from 'ionicons/icons';
import { useParams } from 'react-router';

interface CollectionHeroProps {
    collection: CollectionSlug;
    collectionDetails: CollectionDetails;
}

const CollectionDetailsAndLinks: React.FC<CollectionHeroProps> = ({
    collection,
    collectionDetails,
}) => {

    const { token } = useParams<{ token: string }>();

    return (
        <div
            className='container-with-blur mt3 mb1 pt2 pb3'
            style={{
                minHeight: 'auto',
                marginBottom: '1rem',
                maxWidth: '600px', 
                margin: '0 auto',
            }}
        >
            <div className="description flex flex-wrap flex-column justify-center items-center">
                <p className="pt-serif mt0 mb1 tc pa2 lh-copy text-drop-shadow b">{collectionDetails?.description}</p>
            </div>
            <div
                className="links flex flex-wrap justify-center items-center text-drop-shadow mt2"
            >
                <span className="mb3 mb0-m mb0-l br-m br-l mr0 mr2-m mr2-l dib  b--white-40 ">
                    <ChainLogo collectionDetails={collectionDetails} />
                </span>

                <span className="flex items-center mb3 mb0-m mb0-l br-m br-l mr0 mr2-m mr2-l dib b--white-40">
                    <IonIcon icon={diamond} size='small' className='pl2' style={{
                        width: '1.5rem',
                        height: '1.5rem',
                    }} />
                    <p className="pt-serif mv0 ml2 mr3 b mb2 dib">{collectionDetails?.total_tokens?.toLocaleString()}</p>
                </span>

                <span className='dib'>
                    {collectionDetails?.website ? (
                        <a href={`${collectionDetails?.website}`} target="_blank" rel="noopener noreferrer">
                            <WebsiteIcon className="website-icon" />
                        </a>
                    ) : null}
                    {collectionDetails?.twitter ? (
                        <a href={`${collectionDetails?.twitter}`} target="_blank" rel="noopener noreferrer">
                            <XLogo className="x-logo" />
                        </a>
                    ) : null}
                    {collectionDetails?.opensea ? (
                        <>
                        {token ? (
                            <a href={`https://opensea.io/assets/${collectionDetails?.mainnet_address}/${token}`} target="_blank" rel="noopener noreferrer">
                                <OpenseaLogo className="opensea-logo" />
                            </a>
                        ) : (
                            <a href={`${collectionDetails?.opensea}`} target="_blank" rel="noopener noreferrer">
                                <OpenseaLogo className="opensea-logo" />
                            </a>
                        )}
                        </>
                    ) : null}
                    {collectionDetails?.rarible ? (
                        <>
                        {token ? (
                            <a href={`https://rarible.com/token/${collectionDetails?.mainnet_address}:${token}`} target="_blank" rel="noopener noreferrer">
                                <RaribleLogo className="rarible-logo" />
                            </a>
                        ) : (
                            <a href={`${collectionDetails?.rarible}`} target="_blank" rel="noopener noreferrer">
                                <RaribleLogo className="rarible-logo" />
                            </a>
                        )}                        
                        </>
                    ) : null}
                </span>
            </div>
        </div>
    );
};

export default CollectionDetailsAndLinks;