// React
import { useState } from 'react';

// Ionic
import { IonButton, IonContent, IonFooter, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';

// Images
import bg from './../../images/home/202502/space-gold.jpg';
import cards from './../../images/home/202502/SpaceCards-1920-sml.png';
import gladiatorIcon from './../../images/icons/gladiator-helmet.svg';
import swordsIcon from '../../images/icons/swords.svg';
import brLogo from './../../images/gladiator/brokenrealityinc-logo.png'
import colyseusLogo from './../../images/gladiator/colyseus-logo.png'

// Components
import ParticleOverlay from './ParticleOverlay';
import Version from './Version';

// Pages
import About from '../../pages/About';
import BattleMechanics from '../../pages/Mechanics';

const WelcomeBlock: React.FC = () => {

    const [aboutOpen, setAboutOpen] = useState(false);
    const [mechanicsOpen, setMechanicsOpen] = useState(false);

    return (
        <>
        <div className='welcome-block'>
            <div className="gladiator-welcome-container">
                <div className={`w-100 mt3 flex items-center welcome-content-container relative`}>
                    <div className={`relative ma0 pa0 row welcome-content about-${aboutOpen} mechanics-${mechanicsOpen} z-1 animate__animated animate__zoomIn`} style={{
                        backgroundImage: `url(${bg})`,
                    }}>
                        <ParticleOverlay />

                        {!aboutOpen || !mechanicsOpen ? (
                            <>
                            <img src={cards} alt="" className='cards' />

                            <div className="info-overlay">
                                <div className="about-gladiator">
                                    <IonButton
                                        color='transparent'
                                        className={`mr3`}
                                        onClick={() => {
                                            setAboutOpen(true);
                                        }}
                                    >
                                        <IonIcon src={gladiatorIcon} className='mr2-l w2 h2' />
                                        <span className="button-text f5">About Gladiator</span>
                                    </IonButton>
                                </div>
                                <div className="about-gladiator">
                                    <IonButton
                                        color='transparent'
                                        className={`mr3`}
                                        onClick={() => {
                                            setMechanicsOpen(true);
                                        }}
                                    >
                                        <IonIcon src={swordsIcon} className='mr2-l w2 h2' />
                                        <span className="button-text f5">Game Mechanics</span>
                                    </IonButton>
                                </div>
                                <p className='title pt-serif fw6 version mv0'><Version /></p>
                            </div>
                            </>
                        ) : null}

                    </div>
                </div>
            </div>
        </div>

        {/* About Gladiator Modal */}
        <IonModal isOpen={aboutOpen} backdropDismiss={false} className='release-notes-modal'>
            <IonHeader>
                <IonToolbar>
                    <div className="row ma0 pa0">
                        <div className="columns ma0 pa0 small-3 tl flex items-center">
                            <img src={brLogo} width={28} height={28} alt="" className='ml3' />
                        </div>
                        <div className="columns ma0 pa0 small-6 tl flex items-center justify-center">
                            <IonTitle class='mt2 ml3 tc'><span className="gladiator-arena">About Gladiator</span></IonTitle>
                        </div>
                        <div className="columns ma0 pa0 small-3 tr flex justify-end items-center">
                            <IonButton slot='end' color={'transparent'} onClick={() => setAboutOpen(false)} className='mr3'>Close</IonButton>
                        </div>
                    </div>                  
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <About />
            </IonContent>
            <IonFooter>
                <IonToolbar>
                    <div className="row map pa0">
                        <div className="columns small-6 tl flex items-center">
                            <p className="ma0 pa0 lh-copy f6">
                                &copy; {new Date().getFullYear()} <a href="https://brokenreality.com" target="_blank" rel="noopener noreferrer" className="link white underline-hover pointer">Brokenreality Inc</a>.
                            </p>
                        </div>
                        <div className="columns small-6 tr flex justify-end items-center">
                            <p className='mr3 nowrap f6'>Powered by</p>
                            <a href="https://colyseus.io" target="_blank" rel="noopener noreferrer" className="link white">
                                <img src={colyseusLogo} width={120} alt="" />
                            </a>
                        </div>
                    </div>
                </IonToolbar>
            </IonFooter>
        </IonModal>

        {/* Game Mechanics Modal */}
        <IonModal isOpen={mechanicsOpen} backdropDismiss={false} className='release-notes-modal'>
            <IonHeader>
                <IonToolbar>
                    <div className="row ma0 pa0">
                        <div className="columns ma0 pa0 small-3 tl flex items-center">
                            <img src={brLogo} width={28} height={28} alt="" className='ml3' />
                        </div>
                        <div className="columns ma0 pa0 small-6 tl flex items-center justify-center">
                            <IonTitle class='mt2 ml3 tc'><span className="gladiator-arena">Game Mechanics</span></IonTitle>
                        </div>
                        <div className="columns ma0 pa0 small-3 tr flex justify-end items-center">
                            <IonButton slot='end' color={'transparent'} onClick={() => setMechanicsOpen(false)} className='mr3'>Close</IonButton>
                        </div>
                    </div>                  
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <BattleMechanics />
            </IonContent>
            <IonFooter>
                <IonToolbar>
                    <div className="row map pa0">
                        <div className="columns small-6 tl flex items-center">
                            <p className="ma0 pa0 lh-copy f6">
                                &copy; {new Date().getFullYear()} <a href="https://brokenreality.com" target="_blank" rel="noopener noreferrer" className="link white underline-hover pointer">Brokenreality Inc</a>.
                            </p>
                        </div>
                        <div className="columns small-6 tr flex justify-end items-center">
                            <p className='mr3 nowrap f6'>Powered by</p>
                            <a href="https://colyseus.io" target="_blank" rel="noopener noreferrer" className="link white">
                                <img src={colyseusLogo} width={120} alt="" />
                            </a>
                        </div>
                    </div>
                </IonToolbar>
            </IonFooter>
        </IonModal>



        </>
    );
};

export default WelcomeBlock;